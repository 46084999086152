import axios from "~/plugins/axios";

export default {
  searchArticle(params) {
    // let params = {
    //   store_id,
    //   parent_template_model_id,
    //   parent_article_type_id,
    //   parent_article_class_id,
    //   article_class_id,
    //   page,
    //   page_size
    // };
    return axios
      .get("/ebsn/api/article/search", {
        params: params
      })
      .then(response => response.data)
      .catch(err => err);
  },
  getArticle({ article_id, slug } = {}) {
    let params = {
      article_id,
      slug
    };
    return axios
      .get("/ebsn/api/article/view", {
        params: params
      })
      .then(response => response.data)
      .catch(err => err);
  }
};
