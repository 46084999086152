import axios from "~/plugins/axios";

export default {
  getLang(langInfoName, timeout) {
    return axios
      .get("/ebsn/api/lang/lang-info", {
        params: {
          lang_info_name: langInfoName
        },
        timeout: timeout
      })
      .then(function(data) {
        return data.data;
      })
      .catch(function(error) {
        console.log("Error:getI18nData", error);
      });
  }
};
