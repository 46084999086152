var render = function render(_c,_vm){return _c('div',{staticClass:"title-row d-flex flex-column flex-sm-row align-sm-center"},[_c('ebsn-meta',{attrs:{"target":_vm.props.config,"path":`categoryblocktype_${_vm.props.categoryBlockName}.TITLE`,"tag":"h2","cssClass":"slider-title mr-3"}}),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeInLeft20","leave-active-class":"animate__animated animate__slideOutLeft"}},[(
        _vm.props.showMore &&
          !_vm.parent.$ebsn.meta(
            _vm.props.config,
            'categoryblocktype_' + _vm.props.categoryBlockName + '.HIDE_LINK'
          )
      )?_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.animateLink),expression:"props.animateLink"}],staticClass:"show-more-link align-self-end align-self-md-center mx-3",attrs:{"color":"primary","depressed":"","to":_vm.$options.calcLinkToGo(
          _vm.props.config,
          _vm.props.categoryBlockName,
          _vm.parent.$ebsn.meta
        ),"aria-label":`Vai al ${_vm.props.categoryBlockName}`,"title":`Vai al ${_vm.props.categoryBlockName}`}},[_vm._v(" "+_vm._s(_vm.parent.$ebsn.meta( _vm.props.config, "categoryblocktype_" + _vm.props.categoryBlockName + ".BUTTON_TEXT", _vm.parent.$t("categoryBlock.productListSlider.showAll") ))+" "),_c('v-icon',{staticClass:"chevron-link-icon",attrs:{"small":""}},[_vm._v("$chevronRight")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }