<template>
  <v-card flat class="py-3">
    <v-card-title class="pb-0 pt-0 text-body-1 font-weight-bold"
      >Seleziona una lista a cui aggiungere:</v-card-title
    >
    <v-card-text class="pb-0 pt-1">
      <span v-if="product" class="qty-to-add text-body-2">
        <i>{{ quantityToAdd }} {{ unitToAdd }}</i>
        di {{ product.name }}:
      </span>
      <v-list class="overflow-y-auto" style="max-height: 300px">
        <template v-for="(list, index) in whishlistLists">
          <v-list-item :key="list.id" @click="selectList(list.id)">
            <v-list-item-title>
              <v-badge
                color="primary"
                inline
                :content="list.size"
                :value="list.size > 0"
              >
                {{ list.name }}
              </v-badge>
            </v-list-item-title>
            <!-- <v-list-item-avatar>
              <v-icon v-if="list.isDefault == true">mdi-star</v-icon>
            </v-list-item-avatar>
            <v-divider></v-divider> -->
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-form class="d-flex" @submit.prevent="createAndSelect">
        <v-text-field
          label="Crea una nuova lista"
          hide-details="auto"
          v-model="listName"
          dense
          required
        ></v-text-field>
        <v-btn type="submit" small color="primary">CREA</v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>
<script>
import list from "~/mixins/list";
// import CartService from "~/service/cartService";

export default {
  name: "ListSelector",
  mixins: [list],
  props: {
    quantity: { type: Number, required: false },
    product: { type: Object, required: false }
  },
  computed: {
    whishlistLists() {
      return this.lists;
    },
    quantityToAdd() {
      let multiplier = 1;
      if (
        (this.product.productInfos.TIPOLOGIA === "Sfuso" &&
          this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT) ||
        this.product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        multiplier = Number(this.product.productInfos.WEIGHT_SELLING);
      }
      return multiplier != 1
        ? (this.quantity * multiplier).toFixed(2)
        : this.quantity * multiplier;
    },
    unitToAdd() {
      let unit = "PZ";
      if (this.product.productInfos.TIPOLOGIA === "Pezzo") {
        unit = "PZ";
      } else if (
        this.product.productInfos.TIPOLOGIA === "Sfuso" ||
        this.product.productInfos.TIPOLOGIA === "Confezione"
      ) {
        unit = this.product.productInfos.WEIGHT_UNIT_SELLING;
      }
      return unit;
    }
  },
  methods: {
    selectList(listId) {
      this.$emit("submit", { listId: listId });
    },
    async createAndSelect() {
      let list = await this.createList();
      this.$emit("submit", { listId: list.id });
    }
  },
  created() {
    this.reload();
  }
};
</script>
