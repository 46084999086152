<template>
  <div class="form-detail">
    <v-form
      v-if="!submitted"
      ref="myForm"
      v-model="formValid"
      lazy-validation
      @submit.prevent.stop="submit"
    >
      <v-form-base :model="formModel" :schema="schema"></v-form-base>
      <div v-if="!previewMode" class="form-detail-footer d-flex justify-end">
        <v-btn color="primary" type="submit">Invia</v-btn>
      </div>
    </v-form>
    <div v-else class="thank-you" ref="thankYou">
      <h3 v-if="name">{{ name }}</h3>
      <v-alert type="success">
        {{ descr }}
      </v-alert>
    </div>
    <ResponseMessage :response="responseMessage" class="mt-7" />
  </div>
</template>
<script>
import cuFormService from "@/commons/service/cuFormService";
import ResponseMessage from "@/components/ResponseMessage";
import categoryBlockType from "./categoryBlockType";
import VFormBase from "vuetify-form-base";
import * as validationRules from "~/validator/validationRules";

import forOwn from "lodash/forOwn";
import get from "lodash/get";
import { mapState } from "vuex";

export default {
  name: "FormDetail",
  props: {
    config: { type: Object, required: false },
    previewMode: { type: Boolean, default: false },
    model: { type: Object, default: null }
  },
  mixins: [categoryBlockType],
  components: { VFormBase, ResponseMessage },
  data: () => ({
    form: null,
    formValid: true,
    name: null,
    descr: null,
    formModel: {},
    schema: {},
    submitted: false,
    formId: null,
    responseMessage: {}
  }),
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    async loadSchema() {
      // load schema from server
      if (this.formId == null) return;
      let cuForm = await cuFormService.getFormType(this.formId);
      this.name = cuForm.name;
      this.descr = cuForm.descr;
      this.schema = cuForm.content;

      const processSchema = function(obj) {
        for (let key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            processSchema(obj[key]);
          }
          if (obj["type"] === "file") {
            obj["type"] = "form-file-viewer";
          }

          obj["readonly"] = true;
          obj["clearable"] = false;
        }
      };
      const parseJsonRules = function(value) {
        if (value) {
          value?.jsonRules?.forEach(rule => {
            const f = validationRules[rule.rule](rule.message);
            if (!value.rules) value.rules = [];
            value.rules.push(f);
          });
        }
      };
      // const evaluateExpression = function(value, key, context) {
      //   let defaultValue = value.value;
      //   if (defaultValue) {
      //     if (defaultValue.startsWith("$")) {
      //       //lookup string in current scope
      //       defaultValue = get(context, defaultValue.substring(1), null);
      //     }
      //     context.model[key] = defaultValue;
      //   }
      // };

      if (this.previewMode == true) {
        processSchema(this.schema);
      }
      const _this = this;
      forOwn(this.schema, function(value, key) {
        if (value && (value.type == "wrap" || value.type == "group")) {
          forOwn(value.schema, function(value, key) {
            parseJsonRules(value, key);
            if (value && value.value) {
              let v = get(_this, value.value.substring(1), null);
              if (v) {
                _this.formModel[key] = v;
              }
            }
          });
        } else {
          parseJsonRules(value, key);
          if (value && value.value) {
            if (value.value.startsWith("$")) {
              //lookup string in current scope
              let v = get(_this, value.value.substring(1), null);
              if (v) {
                _this.formModel[key] = v;
              }
            }
          }
        }
      });
    },

    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    isFile(input) {
      return "File" in window && input instanceof File;
    },
    async submit() {
      const _this = this;

      this.responseMessage = {};
      if (_this.$refs.myForm.validate()) {
        // Loop through _this.model
        for (const key in this.formModel) {
          if (this.isFile(this.formModel[key])) {
            const file = this.formModel[key];
            try {
              const base64String = await this.fileToBase64(file);
              this.formModel[key] = base64String;
            } catch (error) {
              console.error("Error converting file to base64:", error);
            }
          }
        }
        let cuForm = await cuFormService.insert(_this.formId, _this.formModel);
        if (cuForm.response && cuForm.response.status == 0) {
          this.content = cuForm.data.content;
          this.submitted = true;
          setTimeout(() => _this.$vuetify.goTo(_this.$refs.thankYou), 150);
        } else {
          this.responseMessage = cuForm.response;
        }
      } else {
        this.responseMessage = {
          errors: [
            {
              error: this.$t("message.incorrectFormMessage")
            }
          ]
        };
      }
    }
  },
  mounted() {
    if (this.model) {
      this.formModel = this.model.content;
      this.formId = this.model.cuFormTypeId;
    } else if (this.config) {
      this.formId = this.$ebsn.meta(
        this.config,
        "categoryblocktype_FormDetail.FORM_ID"
      );
    }
    this.loadSchema();
  }
};
</script>
