var render = function render(){var _vm=this,_c=_vm._self._c;return (
    !_vm.loading &&
      this.config &&
      this.config.proposals &&
      this.config.proposals.length > 0
  )?_c('div',{staticClass:"recipe-slider-container"},[_c('TitleRow',{attrs:{"config":_vm.config,"categoryBlockName":'RecipeSlider',"showMore":true}}),_c('div',{staticClass:"slider news-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.config.proposals),function(proposal){return _c('swiper-slide',{key:proposal.id},[_c('ProposalImage',{attrs:{"proposal":proposal,"position":_vm.config.templateBlock.codInt}})],1)}),1),_c('swiper-paginator',{attrs:{"showBullets":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_RecipeSlider.SHOW_BULLETS',
          true
        ),"showArrows":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_RecipeSlider.SHOW_ARROWS',
          false
        ),"paginationClass":_vm.config.categoryBlockId,"length":_vm.config.proposals.length}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }