<template>
  <v-card outlined rounded class="leaflet-card" :to="leafletDetailUrl">
    <!-- <v-row no-gutters>
      <v-col :cols="small ? 12 : 6"> -->
    <v-img height="300" :alt="cover.alt" eager contain :src="cover.url" />
    <!-- </v-col>
      <v-col :cols="small ? 12 : 6" class="d-flex flex-column"> -->
    <v-card-title>{{ leaflet.title }}</v-card-title>
    <!-- <v-spacer /> -->
    <!-- <v-card-text>
      Valido dal
      {{ leaflet.fromDate | dayjs("format", "D  MMMM") }} al
      {{ leaflet.toDate | dayjs("format", "D  MMMM") }}
    </v-card-text> -->
    <!-- </v-col>
    </v-row> -->
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed>{{ $t("leaflet.goToLeaflet") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
const COVER_TYPE_ID = 12;
export default {
  name: "LeafletCard",
  data() {
    return {};
  },
  props: {
    leaflet: { type: Object, required: true },
    small: { type: Boolean, default: false }
  },
  computed: {
    leafletDetailUrl() {
      if (this.leaflet.leafletTypeId == 2) {
        return {
          name: "volantini",
          params: { leafletId: this.leaflet.slug || this.leaflet.leafletId }
        };
      }
      // DEFAULT --- leafletTypeId = 1 catalogo-soci
      return {
        name: "catalogo-soci",
        params: { leafletId: this.leaflet.slug || this.leaflet.leafletId }
      };
    },
    cover() {
      let media = this.leaflet.media?.find(
        m => m.formatTypeId == COVER_TYPE_ID
      );
      if (!media) {
        media = {
          alt: this.leaflet.title,
          description: this.leaflet.descr,
          url: `${this.leaflet.baseLocation}${this.leaflet.firstPagePreview}`
        };
      }
      return media;
    },
    firstPageSrc() {
      if (this.leaflet.media && this.leaflet.media.length > 0) {
        return `${this.leaflet.media[0].url}`;
      } else {
        return `${this.leaflet.baseLocation}${this.leaflet.firstPagePreview}`;
      }
    }
  }
};
</script>
