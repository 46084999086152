<template>
  <div class="form-file-viewer">
    <v-btn download large :href="obj.value" class="primary"
      ><v-icon class="download-icon pr-2">$download</v-icon> SCARICA FILE
      ALLEGATO</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "FormFileViewer",
  props: ["type", "value", "obj"],
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      } // listen to @input="handler"
    }
  }
};
</script>
