<template>
  <div class="timeslot-selector">
    <div class="d-flex justify-center">
      <img class="logo" :src="serviceImg" height="60" alt="Logo Coop Shop" />
    </div>
    <ebsn-meta
      class="text-center"
      :target="category"
      path="category_info.TITLE"
      :default-value="category.name"
      tag="h3"
    ></ebsn-meta>
    <ebsn-meta
      :target="category"
      path="category_info.DESCRIPTION"
      class="text-center text-body-2 mt-2"
    ></ebsn-meta>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />
    <v-tabs
      hide-slider
      v-model="tab"
      center-active
      show-arrows
      :height="70"
      class="days-tabs"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
        class="days-tab rounded-lg mr-3"
        @click="selectTimeslotDay(timeslotDay)"
      >
        <div
          class="time-div d-flex flex-column align-center justify-space-around"
        >
          <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
            <span class="today text-body-1 font-weight-bold">
              {{ $t("timeslots.today") }}
            </span>
          </template>
          <template v-else>
            <span class="day-week text-body-2">
              {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
            </span>
            <span class="day-month text-body-1 font-weight-bold">
              {{ $dayjs(timeslotDay.dateIso).format("DD") }}
            </span>
            <span class="day-month text-body-2">
              {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
            </span>
          </template>
        </div>
      </v-tab>
    </v-tabs>

    <v-divider class="my-4"></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item
        class="timeslot-container"
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
      >
        <v-row
          v-if="
            timeslotDay &&
              timeslotDay.timeslots &&
              timeslotDay.timeslots.length > 0
          "
        >
          <v-col
            cols="6"
            sm="4"
            v-for="timeslot in timeslotDay.timeslots"
            :key="timeslot.timeslotId"
            ><span class="expires-time">{{
              expires(timeslot.cutoffDate, timeslot.cutoffTime)
            }}</span>
            <v-card
              outlined
              class="timeslot-card"
              :disabled="disabled"
              :class="[
                timeslot.status,
                { selected: timeslot.selected },
                $ebsn.meta(
                  timeslot.deliveryFee,
                  'metaData.deliveryfee_Info.CSS_CLASS'
                )
              ]"
              v-on:click="selectTimeslot(timeslot)"
            >
              <v-card-title class="justify-start flex-nowrap pa-0">
                <div class="time-frame py-2 px-1">
                  {{ timeslot.beginTime }} - {{ timeslot.endTime }}
                </div>
                <div
                  class="time-price py-2 px-1"
                  v-if="
                    timeslot.deliveryFee && timeslot.deliveryFee.promoShortDescr
                  "
                >
                  {{ timeslot.deliveryFee.promoShortDescr }}
                </div>
              </v-card-title>
              <!-- <v-card-subtitle class="text-center">
                {{ $t(`timeslots.availability.${timeslot.status}`) }}
              </v-card-subtitle> -->
            </v-card>
          </v-col>
        </v-row>
        <p v-else>
          <v-alert type="error" outlined>{{ $t("timeslots.noSlots") }}</v-alert>
        </p>
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="mt-4"></v-divider>
    <h6 class="text-uppercase">Legenda</h6>
    <v-row class="legend">
      <v-col cols="12" sm="3">
        <v-card outlined class="timeslot-card empty rounded-lg">
          <v-card-title class="justify-center py-1">
            {{ $t("timeslots.availability.empty") }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card outlined class="timeslot-card  intermediate rounded-lg">
          <v-card-title class="justify-center py-1">
            {{ $t("timeslots.availability.intermediate") }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card outlined class="timeslot-card  full rounded-lg">
          <v-card-title class="justify-center py-1">
            {{ $t("timeslots.availability.full") }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card outlined class="timeslot-card  selected rounded-lg">
          <v-card-title class="justify-center py-1">
            {{ $t("timeslots.availability.selected") }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
  </div>
</template>
<style lang="scss">
.timeslot-selector {
  h3 {
    text-align: center;
  }
  .days-tabs {
    .days-tab {
      min-width: 125px;
      border: 2px solid var(--v-grey-lighten2);
      background-color: var(--v-grey-lighten2);
      border-radius: $border-radius-root;
      .time-div {
        color: var(--v-default-base);
      }
      &.v-tab--active {
        border: 2px solid var(--v-primary-base);
        background-color: var(--v-primary-base);
        .time-div {
          color: var(--v-white-base);
        }
      }
    }
  }
  .timeslot-card {
    .v-card__title {
      padding: 6px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-size: 16px;
      }
    }
    &.selected {
      .v-card__title,
      .v-card__subtitle {
        color: var(--v-white-base);
      }
      background-color: var(--v-primary-base);
      .time-frame {
        color: var(--v-white-base);
        border: 1px solid var(--v-primary-base);
        border-top-left-radius: $border-radius-root;
        border-bottom-left-radius: $border-radius-root;
      }
      &.intermediate {
        .v-card__title .time-frame {
          color: var(--v-default-base);
        }
      }
    }
    &.empty {
      border-color: var(--v-grey-base);
    }
    &.intermediate {
      background-color: #fff4c8;
      border-color: var(--v-grey-base);
      .v-card__title,
      .v-card__subtitle {
        border-color: var(--v-accent-base);
      }
    }
    &.full {
      border-color: var(--v-grey-darken1);
      background-color: var(--v-grey-base);
      .v-card__title {
        color: var(--v-default-base);
      }
    }
    &.promo-yellow-azure {
      .time-price {
        background-color: var(--v-accent-base);
        color: var(--v-secondary-base);
      }
    }
    &.promo-only-azure {
      .time-price {
        margin: -1px;
        color: var(--v-secondary-base);
        border: 1px solid var(--v-secondary-base);
        border-top-right-radius: $border-radius-root;
        border-bottom-right-radius: $border-radius-root;
      }
    }
    &.promo-red-white {
      .time-price {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
      }
    }
    &.promo-red-white-promo {
      .time-price {
        background-color: var(--v-primary-base);
        color: var(--v-white-base);
        line-height: 1rem;
        white-space: initial;
        &::before {
          content: "PROMO";
          font-size: 11px;
          font-weight: normal;
        }
      }
    }
    .time-frame {
      font-size: 14px;
      font-weight: 600;
      flex-grow: 1;
      text-align: center;
    }
    .time-price {
      text-align: center;
      border-left: 1px solid var(--v-grey-base);
      font-size: 16px;
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: nowrap;
    }
  }
  .expires-time {
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }
  .legend {
    .timeslot-card {
      .v-card__title {
        font-size: 14px;
      }
    }
  }
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

import get from "lodash/get";
import cartInfo from "~/mixins/cartInfo";

export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      days: [],
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  },
  methods: {
    openAddressSelector() {
      this.$emit("submit", "setAddress");
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      let _this = this;
      if (timeslot.active < 1) {
        return;
      }
      _this.disabled = true;
      let data = await _this.$store.dispatch("cart/setTimeslot", {
        dateIso: _this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },
    async fetchTimeslots() {
      let _this = this;
      _this.days = await DeliveryService.getTimeslotList(false);
      if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
        _this.days.shift();
      }
      for (var i = 0; i < _this.days.length; i++) {
        if (_this.days[i].dateIso == _this.cart.timeslot.date) {
          _this.tab = i;
          this.selectedTimeslotDay = _this.days[i];
          break;
        }
      }
      if (!_this.selectedTimeslotDay.dateIso) {
        _this.selectTimeslotDay(_this.days[0]);
      }

      return false;
    },
    expires(day, time) {
      let today = this.$dayjs();
      let date = this.$dayjs(day + " " + time, "DD/MM/YYYY hh:mm");
      let diff =
        date.diff(today, "days") > 0
          ? date.diff(today, "days") + " giorni "
          : date.diff(today, "hours") > 0
          ? date.diff(today, "hours") + " ore "
          : date.diff(today, "minute") + " minuti";

      return "scade tra " + diff;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("timeslot");
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
  }
};
</script>
