<template>
  <div style="position:relative" class="leaflet-slider-container">
    <swiper
      :options="swiperOptions"
      ref="leafletSwiperRef"
      v-if="leaflets && leaflets.length > 0"
    >
      <swiper-slide
        class="h-100"
        v-for="leaflet in leaflets"
        :key="leaflet.leafletId"
      >
        <LeafletCard :leaflet="leaflet" :small="small" />
      </swiper-slide>
    </swiper>
    <div id="leaflet-slider-next" class="swiper-button-next"></div>
    <div id="leaflet-slider-prev" class="swiper-button-prev"></div>
  </div>
</template>
<style lang="scss">
.leaflet-slider-container {
  .leaflet-card {
    max-height: 450px;
    width: 300px;
    .v-card__title {
      line-height: 1rem;
    }
    .leaflet-img {
      max-width: 100%;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .swiper-slide {
      width: auto;
      height: auto;
      .leaflet-card.v-card {
        height: 100%;
        overflow: hidden;
      }
    }
  }
}
</style>

<script>
import LeafletCard from "@/components/leaflet/LeafletCard.vue";

import leafletService from "~/service/leafletService.js";

export default {
  name: "LeafletsSlider",
  components: { LeafletCard },
  props: {
    leafletTypeId: { type: Number, required: false },
    parentWarehouseId: { type: Number, required: false },
    deliveryServiceId: { type: Number, required: false },
    small: { type: Boolean, default: false }
  },
  data() {
    return {
      leaflets: null,
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 20,
        autoplay: false,
        navigation: {
          prevEl: `#leaflet-slider-prev`,
          nextEl: `#leaflet-slider-next`
        }
      }
    };
  },
  methods: {
    async reload() {
      this.leaflets = await leafletService.search({
        leaflet_type_id: this.leafletTypeId,
        parent_warehouse_id: this.parentWarehouseId,
        parent_delivery_service_id: this.deliveryServiceId
      });
      if (this.leaflets && this.leaflets.length === 1) {
        this.$router.push({
          name: "catalogo-soci",
          params: {
            leafletId: this.leaflets[0].leafletId
          }
        });
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
