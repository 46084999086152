<template>
  <v-btn
    class="proposal-btn d-flex"
    outlined
    color="primary"
    x-large
    :href="link"
    @click.prevent="handleLink"
  >
    <img
      v-if="proposal.img"
      :src="proposal.img"
      :alt="
        'Naviga ' + proposal.imgDescription || proposal.imgAlt || proposal.descr
      "
      :title="'Naviga ' + proposal.imgDescription"
      :aria-label="
        'Naviga ' + proposal.imgDescription || proposal.imgAlt || proposal.descr
      "
    />
    <span v-if="proposal.descr">{{ proposal.descr }}</span>
  </v-btn>
</template>
<style lang="scss">
.proposal-btn {
  border-width: 2px;
}
</style>
<script>
import banner from "~/mixins/banner";

export default {
  name: "ProposalButton",
  props: {
    proposal: { type: Object, required: true }
  },
  mixins: [banner]
};
</script>
