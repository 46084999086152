import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";

import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

// import Vue from "vue";
// import {
//   Swiper as SwiperClass,
//   Pagination,
//   Navigation,
//   Mousewheel,
//   Autoplay
// } from "swiper/swiper.esm";
// import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay]);
// Vue.use(getAwesomeSwiper(SwiperClass));
// import "swiper/swiper-bundle.min.css"
