<template>
  <div class="leaftlet-viewer-wrapper-container" v-if="leaflet">
    <Leaflet :leafletByProps="leaflet" />
  </div>
</template>
<style lang="scss"></style>
<script>
import Leaflet from "@/components/leaflet/Leaflet.vue";

import leafletService from "~/service/leafletService";

export default {
  name: "LeafletViewerWrapper",
  components: {
    Leaflet
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      leaflet: null
    };
  },
  methods: {
    async fetchLeaflet(leafletId) {
      if (leafletId) {
        let res = await leafletService.getLeafletById(leafletId);
        console.log("fetchLeaflet", res);
        this.leaflet = res;
      }
    }
  },
  mounted() {
    this.fetchLeaflet(
      this.$ebsn.meta(
        this.config,
        "categoryblocktype_LeafletViewerWrapper.LEAFLET_ID"
      )
    );
  }
};
</script>
