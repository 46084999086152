var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-slider-container h-inherit"},[_c('ebsn-meta',{attrs:{"target":_vm.config,"path":"categoryblocktype_ArticleSlider.DESCRIPTION","tag":"div"}}),(_vm.articleList && _vm.articleList.length > 0)?_c('div',{staticClass:"slider article-slider h-inherit"},[_c('swiper',{ref:"swiperRef",staticClass:"h-inherit",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.articleList),function(article){return _c('swiper-slide',{key:article.articleId,staticClass:"swiper-slider-article-card"},[_c('ArticleCard',{attrs:{"article":article}})],1)}),1),_c('swiper-paginator',{attrs:{"showBullets":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_ArticleSlider.SHOW_BULLETS',
          true
        ),"showArrows":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_ArticleSlider.SHOW_ARROWS',
          false
        ),"paginationClass":`banner-${_vm.config.categoryBlockId}`,"length":_vm.articleList.length}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }