import axios from "~/plugins/axios";
import { defaultParams } from "./defaultParams";

export default {
  search(parameters) {
    // var parameters = {
    //   parent_delivery_service_id: pageNumber,
    //   parent_address_type_id: pageSize,
    //   parent_warehouse_id: 0,
    //   parent_zone_id: 0,
    //   parent_address_id: 0,
    //   at_date: 0
    // };

    return axios
      .get("/ebsn/api/leaflet/search", {
        params: { ...parameters, ...defaultParams() }
      })
      .then(response => response.data.data.leaflets)
      .catch(error => error);
  },
  // getLeafletBySlug(_slug) {
  //   return axios
  //     .get("/ebsn/api/leaflet/view", {
  //       params: { slug: _slug }
  //     })
  //     .then(response => response.data)
  //     .catch(error => {
  //       console.log(error);
  //       return null;
  //     });
  // },
  getLeafletById(leafletId) {
    return axios
      .get("/ebsn/api/leaflet/view", {
        params: { leaflet_id: leafletId }
      })
      .then(response =>
        response.data.data.leaflet
          ? response.data.data.leaflet
          : response.data.data
      )
      .catch(error => {
        console.log(error);
        return null;
      });
  },
  searchProducts(params) {
    // Available parameters
    // (name = "searchUid", required = false) String searchUid,
    // (name = "q", required = false) String query,
    // (name = "parent_product_id", required = false) Long[] parentProductId,
    // (name = "parent_category_id", required = false) Integer[] parentCategoryId,
    // (name = "parent_vendor_id", required = false) Integer[] parentVendorId,
    // (name = "parent_product_class_id", required = false) Integer[] parentProductClassId,
    // (name = "parent_catalog_restriction_id", required = false, defaultValue = "") Long[] parentCatalogRestrictionId,
    // (name = "parent_warehouse_promo_facet_id", required = false, defaultValue = "") Long[] parentWarehousePromoIds,
    // (name = "parent_wishlist_id", required = false, defaultValue = "") Long[] parentWishlistIds,

    // (name = "parent_leaflet_id", required = true, defaultValue = "") Long parentLeafletId,
    // (name = "parent_leaflet_page_number_id", required = false, defaultValue = "") Long[] parentLeafletPageNumbers,
    // (name = "parent_leaflet_area_id", required = false, defaultValue = "") Long[] parentLeafletAreaIds,

    // (name = "warehouse_id", required = false, defaultValue = "") Long[] warehouseIds,
    // (name = "warehouse_promo_facet_id", required = false, defaultValue = "") Long[] warehousePromoIds,
    // (name = "category_id", required = false, defaultValue = "") Integer[] categoryIds,
    // (name = "vendor_id", required = false, defaultValue = "") Integer[] vendorIds,
    // (name = "pubdate_from", required = false) Date pubDateFrom,
    // (name = "pubdate_to", required = false) Date pubDateTo,
    // (name = "product_codes", required = false) String productCodes,
    // (name = "version_date_from", required = false) String versionDateFrom,
    // (name = "version_date_to", required = false) String versionDateTo,
    // (name = "weight_from", required = false) Double weightRangeFrom,
    // (name = "weight_to", required = false) Double weightRangeTo,
    // (name = "height_from", required = false) Double heightRangeFrom,
    // (name = "height_to", required = false) Double heightRangeTo,
    // (name = "width_from", required = false) Double widthRangeFrom,
    // (name = "width_to", required = false) Double widthRangeTo,
    // (name = "web_enabled", required = false) Boolean webEnabled,

    // ram(name="product_class_id",required=false,defaultValue="")
    // productClassIds,
    //  MultiValueMap<String, String> params,
    // (name = "promo", defaultValue = "false") boolean promo,
    // (name = "new_product", defaultValue = "false") boolean newProduct,
    // (name = "in_stock", defaultValue = "false") boolean inStock,
    // (name = "page", defaultValue = "1") int page,
    // (name = "page_size", defaultValue = "30") int pageSize,
    // (name = "sort", defaultValue = "rank") String sort,
    // (name = "store_id", required = false) Long storeId,
    // (name = "force", required = false, defaultValue = "false") Boolean force,
    // (name = "preferred", required = false, defaultValue = "false") Boolean preferred) {

    return axios
      .get("/ebsn/api/leaflet/product-search", {
        params: params
      })
      .then(response => response.data)
      .catch(error => {
        console.log(error);
        return null;
      });
  }
};
