<template>
  <v-card
    class="certificate-card d-flex flex-column justify-space-around align-center"
    :class="cssClass"
    @click.prevent.stop="clicked"
    outlined
  >
    <span class="icon-circle">
      <v-icon x-large>{{ icon }}</v-icon>
    </span>
    <span class="promo-body">
      {{ giftCertificate.categoryProposal.name }}
    </span>
    <span class="content" v-html="giftCertificate.categoryProposal.content">
    </span>
    <v-btn v-if="giftCertificate.giftCode" :color="cssClass" @click="add">
      {{ $t("checkout.giftCodeUseNow") }}
    </v-btn>
    <v-btn
      v-if="link"
      outlined
      :color="cssClass"
      :to="link"
      @click="closeWindow"
    >
      {{ btnLabel }}
    </v-btn>
  </v-card>
</template>
<style lang="scss">
.v-card.certificate-card {
  background-color: var(--v-primary-lighten2) !important;
  border-color: var(--v-primary-lighten1);
  width: 210px;
  height: 370px;
  .icon-circle {
    padding: 10px;
    border: 3px solid var(--v-primary-lighten1);
    border-radius: 50%;
    .v-icon {
      color: var(--v-primary-lighten1);
    }
  }
  .promo-body {
    text-align: center;
    min-width: 100px;
    color: white;
    background-color: var(--v-primary-lighten1);
    border-radius: 25px;
    padding: 5px;
    font-weight: bold;
  }
  .content {
    text-align: center;
  }
  &.primary {
    background-color: var(--v-primary-lighten2) !important;
    border-color: var(--v-primary-lighten1);
    .icon-circle {
      border-color: var(--v-primary-lighten1);
      .v-icon {
        color: var(--v-primary-lighten1);
      }
    }
    .promo-body {
      background-color: var(--v-primary-lighten1);
    }
  }
  &.secondary {
    background-color: var(--v-secondary-lighten2) !important;
    border-color: var(--v-secondary-lighten2);
    .icon-circle {
      border-color: var(--v-secondary-lighten1);
      .v-icon {
        color: var(--v-secondary-lighten1);
      }
    }
    .promo-body {
      color: $text-color;
      background-color: var(--v-secondary-lighten1);
    }
  }
  &.warning {
    background-color: var(--v-warning-lighten3) !important;
    border-color: var(--v-warning);
    .icon-circle {
      border-color: var(--v-warning-lighten1);
      .v-icon {
        color: var(--v-warning-lighten1);
      }
    }
    .promo-body {
      color: $text-color;
      background-color: var(--v-warning-lighten1);
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "ProposalGiftCard",
  mixins: [clickHandler, giftCertificate],
  methods: {
    closeWindow() {
      this.$emit("submit");
    }
  }
};
</script>
