<template>
  <div class="proposal-tutorial-slider swiper-container-wrapper">
    <swiper :options="swiperOption" ref="swiperRef" v-if="proposals">
      <swiper-slide v-for="proposal in proposals" :key="proposal.id">
        <ProposalTutorialSlide
          :proposal="proposal"
          @skipTutorial="skipTutorial"
        />
      </swiper-slide>
      <div
        v-if="proposals.length > 0"
        class="swiper-pagination swiper-pagination-tutorial"
        slot="pagination"
      ></div>
    </swiper>
  </div>
</template>
<style lang="scss">
.proposal-tutorial-slider {
  &.swiper-container-wrapper {
    height: 100%;
  }
  .swiper-container,
  .swiper-page {
    height: 100%;
    justify-content: space-evenly;
  }
  .swiper-pagination-tutorial {
    bottom: 30px;
    width: 100%;
    left: 50%;
  }
  .proposal-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .content {
    font-size: 17px;
  }
  .proposal-title,
  .content {
    text-align: center;
  }
}
</style>
<script>
import ProposalTutorialSlide from "@/components/tutorial/ProposalTutorialSlide.vue";

export default {
  name: "ProposalTutorialSlider",
  components: {
    ProposalTutorialSlide
  },
  props: {
    proposals: { type: Array, required: true }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        autoplay: false,
        freeMode: false,
        observer: true,
        observeParents: true,
        watchOverflow: true,
        pagination: {
          el: ".swiper-pagination-tutorial"
        }
      }
    };
  },
  methods: {
    skipTutorial() {
      this.$emit("skipTutorial");
    }
  }
};
</script>
