import axios from "~/plugins/axios";
import store from "@/store";
export default {
  data() {
    return {
      deviceToken: null
    };
  },
  handlePush(notification) {
    // console.log("::: HANDLEPUSH FIREBASE ::::: ", notification);
    // console.log("Message type: " + notification.messageType);
    // console.log("Tapped in " + notification.tap);
    if (notification) {
      if (notification.aps && notification.aps.alert) {
        //iOS - NAV TO
        if (notification && notification.tap === "background") {
          if (notification.nav_to) {
            global.vm.$router.push(notification.nav_to);
          }
        } else {
          global.EventBus.$emit("notification", {
            title: notification.aps.alert.title,
            message: notification.body,
            callback: value => {
              if (value) {
                if (notification.nav_to) {
                  global.vm.$router.push(notification.nav_to);
                }
              }
            }
          });
        }
      } else if (notification.title) {
        // ANDROID
        if (notification && notification.tap === "background") {
          if (notification.nav_to) {
            global.vm.$router.push(notification.nav_to);
          }
        } else {
          global.EventBus.$emit("notification", {
            title: notification.title,
            message: notification.body,
            callback: value => {
              if (value) {
                if (notification.nav_to) {
                  global.vm.$router.push(notification.nav_to);
                }
              }
            }
          });
        }
      }
    }
  },
  async unRegisterFirebaseDevice() {
    console.log("::: FIREBASE ::::: UNREGISTER FIREBASE START");
    if (window.FirebasePlugin) {
      window.FirebasePlugin.unregister();
      // eslint-disable-next-line no-undef
      this.unRegister(device);
    }
  },
  async registerFirebaseDevice() {
    let _this = this;
    console.log("::: FIREBASE ::::: REGISTER FIREBASE START");
    if (window.FirebasePlugin) {
      window.FirebasePlugin.grantPermission(
        function() {
          console.log("::: FIREBASE ::::: Push granted");
        },
        function() {
          console.log("::: FIREBASE ::::: Push not granted");
        }
      );
      window.FirebasePlugin.onMessageReceived(_this.handlePush);
      window.FirebasePlugin.getToken(
        function(token) {
          // eslint-disable-next-line no-undef
          _this.register(token, device);
          _this.deviceToken = token;
          window.FirebasePlugin.subscribe("all");
        },
        function(error) {
          console.error("::::: ERROR GET TOKEN :::: ", error);
        }
      );

      window.FirebasePlugin.onTokenRefresh(
        function(token) {
          if (store && store.getters["cart/isAuthenticated"] == true) {
            // eslint-disable-next-line no-undef
            _this.register(token, device);
            _this.deviceToken = token;
          }
        },
        function(error) {
          console.error("::: ERROR FIREBASE :::::", error);
        }
      );
    }
  },
  register(externalId, device) {
    console.log("::: SERVICE REGISTER ::::: ", { externalId, device });
    return axios
      .get("/ebsn/api/externalregistration/register", {
        params: {
          external_id: externalId,
          model: device.model,
          platform: device.platform,
          platform_version: device.version,
          manufacturer: device.manufacturer,
          uuid: device.uuid
        }
      })
      .then(data => {
        return data;
      });
  },
  unRegister(device) {
    console.log("::: SERVICE UNREGISTER ::::: ", device);
    return axios
      .get("/ebsn/api/externalregistration/unregister", {
        params: {
          platform: device.platform,
          uuid: device.uuid
        }
      })
      .then(data => {
        return data;
      });
  }
};
