<template>
  <div>
    <h5
      v-if="obj.schema.title"
      v-html="obj.schema.title"
      class="font-weight-bold"
    ></h5>
    <h6 v-if="obj.schema.title" v-html="obj.schema.subtitle"></h6>
    <p v-if="obj.schema.content" v-html="obj.schema.content"></p>
  </div>
</template>
<script>
export default {
  name: "FormText",
  props: ["type", "value", "obj"],
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      } // listen to @input="handler"
    }
  }
};
</script>
