<template>
  <div class="article-slider-container h-inherit">
    <ebsn-meta
      :target="config"
      path="categoryblocktype_ArticleSlider.DESCRIPTION"
      tag="div"
    />
    <div
      class="slider article-slider h-inherit"
      v-if="articleList && articleList.length > 0"
    >
      <swiper :options="swiperOption" ref="swiperRef" class="h-inherit">
        <swiper-slide
          v-for="article in articleList"
          :key="article.articleId"
          class="swiper-slider-article-card"
        >
          <ArticleCard :article="article" />
        </swiper-slide>
      </swiper>
      <swiper-paginator
        :showBullets="
          $ebsn.meta(
            config,
            'categoryblocktype_ArticleSlider.SHOW_BULLETS',
            true
          )
        "
        :showArrows="
          $ebsn.meta(
            config,
            'categoryblocktype_ArticleSlider.SHOW_ARROWS',
            false
          )
        "
        :paginationClass="`banner-${config.categoryBlockId}`"
        :length="articleList.length"
      />
    </div>
  </div>
</template>
<style lang="scss">
.article-slider-container {
  .swiper-slide.swiper-slider-article-card {
    height: auto;
  }
  .article-card {
    height: 100%;
  }
}
</style>
<script>
import ArticleCard from "@/components/article/ArticleCard.vue";
import SwiperPaginator from "../SwiperPaginator.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import mksCmService from "~/service/mksCmService";

export default {
  name: "ArticleSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ArticleCard,
    SwiperPaginator
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      articleList: null,
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#pagination-banner-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-banner-${this.config.categoryBlockId}`,
          nextEl: `#next-banner-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    async reload() {
      let _this = this;
      let parentArticleTypeId = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.ARTICLE_TYPE"
      );

      let parentArticleClassId = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.ARTICLE_CLASS_FILTER"
      );
      let sort = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.ARTICLE_SORT"
      );
      let limit = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleSlider.LIMIT"
      );
      let params = {
        parent_article_type_id: parentArticleTypeId,
        parent_article_class_id: parentArticleClassId,
        sort: sort,
        page_size: limit ? limit : 8
      };

      let content = await mksCmService.searchArticle(params);
      this.articleList = content?.data?.articles;
      _this.$nextTick(() => {
        if (_this.$refs.swiperRef && _this.$refs.swiperRef.swiperInstance) {
          _this.$refs.swiperRef.swiperInstance.slideTo(0);
        }
      });
      if (!this.articleList || this.articleList.length < 1) {
        this.$emit("hide");
      }
    }
  },
  created() {
    this.reload();
  }
};
</script>
