<template>
  <iframe
    class="leaflet-iframe rounded-sm"
    :src="decodeVideoEmbedURL(videoURL)"
    frameborder="0"
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>
<style lang="scss">
.leaflet-iframe {
  background-color: black;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  margin-bottom: -6px;
  min-height: calc(70vh);
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: calc(80vh);
  }
}
</style>
<script>
import { decodeVideoEmbedURL } from "~/helper";
export default {
  name: "EmbedVideo",
  props: {
    videoURL: { type: String, required: true }
  },
  methods: {
    decodeVideoEmbedURL
  }
};
</script>
