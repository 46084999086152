const routes = [
  {
    name: "WarehouseDetail",
    path: "/punti-vendita/:warehouseSlug",
    // props: true,
    meta: {
      breadCrumb: "Punto vendita",
      site: "site"
    },
    component: () => import("@/views/store/WarehouseDetail.vue")
  },
  {
    name: "ServiceAutodiscovery",
    path: "/service-discovery",
    meta: {
      breadCrumb: "Verifica il servizio",
      site: "site"
    },
    props: true,
    component: () => import("@/components/store/ServiceAutodiscovery.vue")
  }
];

export default routes;
