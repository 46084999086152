import axios from "~/plugins/axios";

export default {
  getCustomerLifetimeData(userId) {
    const url = `/ebsn/api/customer-lifetime/user_info?userId=${userId}`;
    return axios
      .post(url)
      .then(data => data.data.data)
      .catch(error => error);
  }
};
