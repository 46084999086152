import CartService from "~/service/cartService";
import DeliveryService from "~/service/deliveryService";
import SecurityService from "~/service/securityService";
import AnalyticsService from "~/service/analyticsService";
import PushNotificationService from "~/service/pushNotificationService";
import SocialService from "~/service/socialService";
import customerLifetime from "~/service/customerLifetime";
import { forEachCartItem } from "~/service/ebsn";

import get from "lodash/get";
import join from "lodash/join";
import find from "lodash/find";
import filter from "lodash/filter";
import backofficeService from "~/service/backofficeService";

const SET_CART = "SET_CART";
const SET_SSOUSER = "SET_SSOUSER";
const SET_AUTH = "SET_AUTH";

const state = {
  cart: {},
  ssoUser: {},
  itemMaps: new Map()
};

const getters = {
  isAuthenticated: state => {
    return state.cart && state.cart.user != null && state.cart.user.userId > -1;
  },
  isSsoAuthenticated: state => {
    return state && state.ssoUser && state.ssoUser.username != undefined;
  },
  isSsoUser: state => {
    return state.ssoUser;
  },
  isMasterAccount: state => {
    return (
      state.cart.user.userType.userTypeId === "1" ||
      state.cart.user.userType.userTypeId === "2"
    );
  },
  isBetaTester: state => {
    return state.cart.user && state.cart.user.betaTester == "1";
  },
  getUser: state => {
    return get(state.cart, "user");
  },
  getGiftCertificatesReceived: state => {
    return get(state.cart, "user.giftCertificatesReceived");
  },
  getAddressId: state => {
    return state.cart.shippingAddress
      ? state.cart.shippingAddress.addressId
      : 0;
  },
  canChangeAddress: () => {
    return true;
  },
  getStoreId: state => {
    return get(state.cart, "store.storeId");
  },
  getWarehouseId: state => {
    return get(state.cart, "warehouse.warehouseId", 0);
  },
  getWarehouse: state => {
    return get(state.cart, "warehouse", null);
  },
  getDeliveryServiceId: state => {
    return state.cart.shippingAddress
      ? state.cart.shippingAddress.deliveryServiceId
      : 0;
  },
  getTimeslotId: state => {
    return state.cart.timeslot
      ? state.cart.timeslot.date + state.cart.timeslot.timeslotId
      : 0;
  },
  getTimeslot: state => {
    return state.cart.timeslot;
  },
  getZoneId: state => {
    return state.cart.shippingAddress ? state.cart.shippingAddress.zoneId : 0;
  },
  cacheBurst: state => {
    let hash =
      "w" +
      get(state.cart, "warehouse.warehouseId", 0) +
      "d" +
      get(state.cart, "timeslot.date", 0) +
      "t" +
      get(state.cart, "state.cart.timeslot.timeslotId", 0);
    return hash;
  },
  hasFavorites: state => {
    return get(state.cart, "user.hasFavorites", false);
  },
  hasRole: state => (...roles) => {
    if (state.ssoUser.roles) {
      const found = roles.some(function(role) {
        return state.ssoUser.roles.includes(role);
      });
      return found;
    }
    return false;
  },
  getCartStatus: state => {
    return state.cart.cartStatusId;
  },
  isTimeslotAssigned: state => {
    if (global.config.bypassTimeslotAssignment) return true;
    return !get(state.cart, "timeslot.autoAssigned");
  },
  isAddressAssigned: state => {
    if (global.config.bypassAddressAssignment) return true;
    return !get(state.cart, "shippingAddress.autoAssigned");
  },
  getItemByProductId: state => productId => {
    if (state.cart.cartItems) {
      for (let x = 0; x < state.cart.cartItems.length; x++) {
        let cartItem = state.cart.cartItems[x];
        if (cartItem.product.productId === productId) {
          return cartItem;
        }
      }
    } else if (state.cart.sectors) {
      for (let y = 0; y < state.cart.sectors.length; y++) {
        let sector = state.cart.sectors[y];
        for (let x = 0; x < sector.cartItems.length; x++) {
          let cartItem = sector.cartItems[x];
          if (cartItem.product.productId === productId) {
            return cartItem;
          }
        }
      }
    }
    return null;
  },
  getOrderAdjustmentByItemId: state => itemId => {
    if (
      state.cart.orderAdjustmentSummary &&
      state.cart.orderAdjustmentSummary.orderAdjustments
    ) {
      return find(
        state.cart.orderAdjustmentSummary.orderAdjustments,
        orderAdjustment => orderAdjustment.orderItemId == itemId
      );
    }
    return null;
  },
  getGiftOrderAdjustmentByItemId: state => itemId => {
    if (
      state.cart.orderAdjustmentSummary &&
      state.cart.orderAdjustmentSummary.orderAdjustments
    ) {
      return find(
        state.cart.orderAdjustmentSummary.orderAdjustments,
        orderAdjustment =>
          orderAdjustment.orderItemId == itemId &&
          orderAdjustment.userGiftCertificateId &&
          orderAdjustment.giftCertificate
      );
    }
    return null;
  },
  getOrderAdjustmentListByItemId: state => itemId => {
    if (
      state.cart.orderAdjustmentSummary &&
      state.cart.orderAdjustmentSummary.orderAdjustments
    ) {
      return filter(
        state.cart.orderAdjustmentSummary.orderAdjustments,
        orderAdjustment => orderAdjustment.orderItemId == itemId
      );
    }
    return null;
  },
  getItem: state => params => {
    if (state.itemMaps.get) {
      let key = getItemKey(params);
      let item = state.itemMaps.get(key);
      return item;
    }
    return null;
  },
  getSelectedService: state => {
    return state.cart.shippingAddress.deliveryServiceId;
  },
  needInvoice: state => {
    return state.cart.cartInfos.need_invoice === "true";
  },
  profileLevel: state => {
    return state.cart && state.cart.user && state.cart.user.profile
      ? state.cart.user.profile.level
      : 0;
  },
  getLegalById: state => legalId => {
    let user = state.cart.user;
    if (!user || !user.legals) {
      return false;
    }
    for (var i = 0; i < user.legals.length; i++) {
      if (user.legals[i].legalId == legalId) {
        return true;
      }
    }
    return false;
  },
  getCrmSegmentId: state => crmSegmentId => {
    if (state.cart.user && typeof state.cart.user.crmUserSegments == "object") {
      return state.cart.user.crmUserSegments.find(
        segment => segment.crmSegment.crmSegmentId == crmSegmentId
      );
    }
    return null;
  }
};

const actions = {
  async doLogin({ commit }, payload) {
    let data = await SecurityService.login(
      payload.email,
      payload.password,
      payload.rememberMe,
      payload.token
    );

    // if (data.user) {
    if (data.user && data.user.userId > -1) {
      commit(SET_CART, data);
      AnalyticsService.login(data, payload.token ? "social" : "form");
      const customerLoginData = customerLifetime.getCustomerLifetimeData(
        data.user.userId
      );
      if (customerLoginData) {
        AnalyticsService.trackEvent(
          "userDataLoaded",
          Object.keys(customerLoginData),
          Object.values(customerLoginData)
        );
      }
      if (global.config.pushNotificationEnabled) {
        PushNotificationService.registerFirebaseDevice();
        if (window.MCCordovaPlugin && data.user.companyId == 32) {
          let contactKeyResult = await SecurityService.getMarketingCloudContactKey(
            data.user.email,
            1
          );
          if (contactKeyResult) {
            let channelAddress =
              contactKeyResult.channelAddressResponseEntities[0];
            let contactKey = "";
            for (var i = 0; i < channelAddress.contactKeyDetails.length; i++) {
              if (
                channelAddress.contactKeyDetails[i].contactKey &&
                channelAddress.contactKeyDetails[i].contactKey.length > 0
              ) {
                contactKey = channelAddress.contactKeyDetails[i].contactKey;
                break;
              }
            }

            console.log("CONTACTKEY: " + contactKey);

            window.MCCordovaPlugin.enablePush(
              function(d) {
                console.log("MC Push enalbled", d);
              },
              function(e) {
                console.log(e);
              }
            );

            window.MCCordovaPlugin.setContactKey(
              // eslint-disable-next-line no-undef
              contactKey,
              function(keys) {
                console.log("AFTER LOGIN // SET CONTACT KEY: " + keys);
              },
              function(error) {
                console.log("AFTER LOGIN // ERROR SETTING DEVICE_ID: " + error);
              }
            );

            window.MCCordovaPlugin.enableLogging(
              function(logs) {
                console.log("AFTER LOGIN // LOGGIN ENABLED: " + logs);
              },
              function(errorLog) {
                console.log(
                  "AFTER LOGIN // ERROR ENABLING LOGGING: " + errorLog
                );
              }
            );

            window.MCCordovaPlugin.getDeviceId(
              function(data) {
                console.log("AFTER LOGIN // GOT DEVICE_ID: " + data);
              },
              function(err) {
                console.log("AFTER LOGIN // ERROR GETTING DEVICE_ID: " + err);
              }
            );
          }
        }
      }
    }
    // else if (data.ssoUser) {
    //   commit(SET_SSOUSER, data.ssoUser);
    // }
    return data;
  },
  async doLogout({ commit }) {
    try {
      if (window.FirebasePlugin) {
        window.FirebasePlugin.unregister();
        // eslint-disable-next-line no-undef
        PushNotificationService.unRegister(device);
      }
      if (window.MCCordovaPlugin && this.state.cart.cart.user.companyId == 32) {
        window.MCCordovaPlugin.disablePush(
          function(d) {
            console.log("AFTER LOGOUT // MC PUSH DISABLED: " + d);
          },
          function(e) {
            console.log("AFTER LOGOUT // ERROR DISABLING MC PUSH: " + e);
          }
        );

        var _thisM = this;

        window.MCCordovaPlugin.getContactKey(
          function(contactKey) {
            console.log("AFTER LOGOUT // MC PUSH CONTACTKEY: " + contactKey);
            let userId = _thisM.state.cart.cart.user.userId;
            SecurityService.removeMarketingCloudContactKey(contactKey, userId);
          },
          function(e) {
            console.log(
              "AFTER LOGOUT // ERROR GETTING MC PUSH CONTACT KEY: " + e
            );
          }
        );
      }
      await SecurityService.logout();
      commit(SET_CART, {});
      AnalyticsService.logout();
      //window.location.reload();
      return {};
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorLogout")
      });
      return null;
    }
  },
  async doSsoLogout({ commit }) {
    try {
      await backofficeService.logout();
      commit(SET_SSOUSER, {});
      commit(SET_CART, {});

      return true;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorLogout")
      });
      return null;
    }
  },
  async doSamlLogin({ commit }, params) {
    let data = await SocialService.samlLogin(params);

    if (data.user) {
      commit(SET_CART, data);
      if (global.config.pushNotificationEnabled) {
        PushNotificationService.registerFirebaseDevice();
      }
    }
    return data;
  },
  async doSamlLogout({ commit }) {
    try {
      if (window.FirebasePlugin) {
        window.FirebasePlugin.unregister();
        // eslint-disable-next-line no-undef
        PushNotificationService.unRegister(device);
      }
      await SocialService.samlLogout();
      commit(SET_CART, {});
      return {};
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorLogout")
      });
      return null;
    }
  },
  async setAddress({ commit }, params) {
    try {
      const data = await DeliveryService.setAddress(
        params.addressType,
        params.addressId,
        params.deliveryServiceId
      );
      if (data) {
        const cart = await CartService.getCart();
        commit(SET_CART, cart);
        AnalyticsService.selectAddress(cart);
        return data;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorSettingAddress")
      });
      return null;
    }
  },
  async setTimeslot({ commit }, params) {
    try {
      const data = await DeliveryService.setTimeslot(
        params.dateIso,
        params.timeslotId
      );
      if (data) {
        const cart = await CartService.getCart();
        commit(SET_CART, cart);
        AnalyticsService.selectTimeslot(cart);
        return data;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorSettingTimeslot")
      });
      return null;
    }
  },
  async setStore({ commit }, params) {
    try {
      const data = await DeliveryService.setStore(params.storeId);
      if (data) {
        const cart = await CartService.getCart();
        commit(SET_CART, cart);
        return data;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorSettingStore")
      });
      return null;
    }
  },
  async loadCart({ commit }) {
    try {
      const cart = await CartService.getCart();
      commit(SET_CART, cart);
      AnalyticsService.initCustomDimension(cart);
      return cart;
    } catch (err) {
      AnalyticsService.initCustomDimension();
      if (this.isAuthenticated) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorLoadingCart")
        });
      }
      return null;
    }
  },
  async lockCart({ commit }, params) {
    let cart = await CartService.lockCart(params);
    commit(SET_CART, cart);
    return cart;
  },
  async buyCart({ commit }, params) {
    let order = await CartService.buy(params);
    const cart = await CartService.getCart();
    commit(SET_CART, cart);
    return order;
  },
  async getCart({ commit }) {
    let cart = await CartService.getCart();
    commit(SET_CART, cart);
    return cart;
  },
  async setSelectedWarehouse({ commit }, params) {
    let cart = await CartService.getCart();
    cart.selectWarehouse = params.warehouse;
    commit(SET_CART, cart);
    return cart;
  },
  async setCartInfo({ commit }, params) {
    try {
      let cart;
      for (var key of Object.keys(params)) {
        cart = await CartService.setCartInfo(key, params[key]);
        AnalyticsService.checkoutOptions(2, key, params[key]);
      }
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorSettingCartInfo")
      });
      return null;
    }
  },
  async setCartItemInfo({ commit }, params) {
    try {
      let cart = await CartService.setCartItemInfo(
        params.items,
        params.name,
        params.value
      );

      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorSettingCartInfo")
      });
      return null;
    }
  },
  async addProduct({ commit }, params) {
    try {
      const cart = await CartService.addProduct(
        params.product,
        params.quantity,
        params.params,
        params.searchParams
      );

      commit(SET_CART, cart);
      AnalyticsService.addToCart([
        {
          product: params.product,
          quantity: params.quantity
        }
      ]);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("cart.productAddError")
      });
      return null;
    }
  },
  async addProducts({ commit }, params) {
    try {
      const cart = await CartService.addProducts(params.products);

      commit(SET_CART, cart);
      // AnalyticsService.addToCart([
      //   {
      //     product: params.product,
      //     quantity: params.quantity
      //   }
      // ]);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("cart.productsAddError")
      });
      return null;
    }
  },
  async addGiftCode({ commit }, giftCodeId) {
    try {
      const cart = await CartService.addGiftCode(giftCodeId);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: global.EventBus.$t("errors.errorAddingProduct")
      // });
      return null;
    }
  },
  async sendGiftCode({ commit }, giftCode) {
    try {
      const cart = await CartService.sendGiftCode(giftCode);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: global.EventBus.$t("errors.errorAddingProduct")
      // });
      return null;
    }
  },
  async removeGiftCode({ commit }, giftCodeId) {
    try {
      const cart = await CartService.removeGiftCode(giftCodeId);

      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      // global.EventBus.$emit("error", {
      //   error: err,
      //   message: global.EventBus.$t("errors.errorAddingProduct")
      // });
      return null;
    }
  },
  async addProductsFromOrder({ commit }, orderId) {
    try {
      const cart = await CartService.allProductsFromOrder(orderId);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorAddingProductFromOrder")
      });
      return null;
    }
  },
  async addProductsFromWishlist({ commit }, listId) {
    try {
      const cart = await CartService.allProductsFromWishlist(listId);
      commit(SET_CART, cart);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorAddingProductFromList")
      });
      return null;
    }
  },
  async setItem({ commit }, params) {
    try {
      const cart = await CartService.setItem(
        params.item,
        params.quantity,
        params.searchParams
      );
      commit(SET_CART, cart);
      if (params.quantity < params.item.quantity) {
        AnalyticsService.removeFromCart([
          {
            product: params.item.product,
            quantity: params.item.quantity - params.quantity
          }
        ]);
      } else {
        AnalyticsService.addToCart([
          {
            product: params.item.product,
            quantity: params.quantity - params.item.quantity
          }
        ]);
      }
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorSettingProduct")
      });
      return null;
    }
  },
  async removeItem({ commit }, item) {
    try {
      const cart = await CartService.removeItem(item);
      commit(SET_CART, cart);
      AnalyticsService.removeFromCart([item]);
      return cart;
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.errorRemovingProduct")
      });
      return null;
    }
  },
  async emptyCart({ commit, state }, items) {
    try {
      if (items) {
        global.EventBus.$emit("removeAllGift", items);
      }
      if (state.cart.totalItems > 0) {
        const cart = await CartService.emptyCart(state.cart);
        commit(SET_CART, cart);
        if (items && items.length > 0) AnalyticsService.removeFromCart([items]);
        return cart;
      }
    } catch (err) {
      global.EventBus.$emit("error", {
        error: err,
        message: global.EventBus.$t("errors.emptyCart")
      });
      return null;
    }
  },
  async updateAuth({ commit }) {
    commit(SET_AUTH, -1);
  },
  async setSsoUser({ commit }, ssoUser) {
    commit(SET_SSOUSER, ssoUser);
  },
  async checkAuthentication({ state }) {
    if (state.cart && state.cart.user != null && state.cart.user.userId == -1) {
      return false;
    } else {
      const result = await CartService.isLoggedIn();
      return result;
    }
  }
};

const mutations = {
  [SET_CART](state, value) {
    state.cart = value;
    //if using additive cart rebuild cart map
    if (global.config.updateCart || global.config.buildItemsMap) {
      let map = new Map();
      forEachCartItem(value, cartItem => {
        // only products added by users
        if (cartItem.cartItemInsertionTypeId == 1) {
          map.set(getItemKey(cartItem), cartItem);
        }
      });
      state.itemMaps = map;
    }
  },
  [SET_AUTH](state, value) {
    state.cart.user.userId = value;
  },
  [SET_SSOUSER](state, ssoUser) {
    state.ssoUser = ssoUser;
  },
  updateSelectedService: (state, value) => {
    state.selectedService = value;
  }
};

function getItemKey(item) {
  let keys = [];
  let keysMap = item.cartItemInfo || item;
  keys.push(item.product ? item.product.productId : item.productId);
  if (global.config.cartItemInfos) {
    global.config.cartItemInfos.forEach((key, i) => {
      let tVal = keysMap[key];
      if (!tVal || tVal == "-") {
        keys.push(i);
      } else {
        keys.push(tVal);
      }
    });
  }
  let hash = join(keys, "_");
  return hash;
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
