var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeslot-selector"},[_c('div',{staticClass:"d-flex justify-center"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.serviceImg,"height":"60","alt":"Logo Coop Shop"}})]),_c('ebsn-meta',{staticClass:"text-center",attrs:{"target":_vm.category,"path":"category_info.TITLE","default-value":_vm.category.name,"tag":"h3"}}),_c('ebsn-meta',{staticClass:"text-center text-body-2 mt-2",attrs:{"target":_vm.category,"path":"category_info.DESCRIPTION"}}),_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"position1","container":false}}),_c('v-tabs',{staticClass:"days-tabs",attrs:{"hide-slider":"","center-active":"","show-arrows":"","height":70},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.days),function(timeslotDay){return _c('v-tab',{key:timeslotDay.dateIso,staticClass:"days-tab rounded-lg mr-3",on:{"click":function($event){return _vm.selectTimeslotDay(timeslotDay)}}},[_c('div',{staticClass:"time-div d-flex flex-column align-center justify-space-around"},[(_vm.$dayjs().isSame(timeslotDay.dateIso, 'day'))?[_c('span',{staticClass:"today text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.today"))+" ")])]:[_c('span',{staticClass:"day-week text-body-2"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("dddd"))+" ")]),_c('span',{staticClass:"day-month text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("DD"))+" ")]),_c('span',{staticClass:"day-month text-body-2"},[_vm._v(" "+_vm._s(_vm.$dayjs(timeslotDay.dateIso).format("MMMM"))+" ")])]],2)])})],2),_c('v-divider',{staticClass:"my-4"}),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.days),function(timeslotDay){return _c('v-tab-item',{key:timeslotDay.dateIso,staticClass:"timeslot-container"},[(
          timeslotDay &&
            timeslotDay.timeslots &&
            timeslotDay.timeslots.length > 0
        )?_c('v-row',_vm._l((timeslotDay.timeslots),function(timeslot){return _c('v-col',{key:timeslot.timeslotId,attrs:{"cols":"6","sm":"4"}},[_c('span',{staticClass:"expires-time"},[_vm._v(_vm._s(_vm.expires(timeslot.cutoffDate, timeslot.cutoffTime)))]),_c('v-card',{staticClass:"timeslot-card",class:[
              timeslot.status,
              { selected: timeslot.selected },
              _vm.$ebsn.meta(
                timeslot.deliveryFee,
                'metaData.deliveryfee_Info.CSS_CLASS'
              )
            ],attrs:{"outlined":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.selectTimeslot(timeslot)}}},[_c('v-card-title',{staticClass:"justify-start flex-nowrap pa-0"},[_c('div',{staticClass:"time-frame py-2 px-1"},[_vm._v(" "+_vm._s(timeslot.beginTime)+" - "+_vm._s(timeslot.endTime)+" ")]),(
                  timeslot.deliveryFee && timeslot.deliveryFee.promoShortDescr
                )?_c('div',{staticClass:"time-price py-2 px-1"},[_vm._v(" "+_vm._s(timeslot.deliveryFee.promoShortDescr)+" ")]):_vm._e()])],1)],1)}),1):_c('p',[_c('v-alert',{attrs:{"type":"error","outlined":""}},[_vm._v(_vm._s(_vm.$t("timeslots.noSlots")))])],1)],1)}),1),_c('v-divider',{staticClass:"mt-4"}),_c('h6',{staticClass:"text-uppercase"},[_vm._v("Legenda")]),_c('v-row',{staticClass:"legend"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-card',{staticClass:"timeslot-card empty rounded-lg",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center py-1"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.availability.empty"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-card',{staticClass:"timeslot-card intermediate rounded-lg",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center py-1"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.availability.intermediate"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-card',{staticClass:"timeslot-card full rounded-lg",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center py-1"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.availability.full"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-card',{staticClass:"timeslot-card selected rounded-lg",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"justify-center py-1"},[_vm._v(" "+_vm._s(_vm.$t("timeslots.availability.selected"))+" ")])],1)],1)],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2","container":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }