<template>
  <div
    class="proposal-tutorial-slide d-flex flex-column swiper-page"
    :class="'proposal-tutorial-slide-' + proposal.id"
    @click="handleLink"
  >
    <v-img
      class="proposal-img"
      :src="getImage(proposal)"
      :alt="proposal.name"
    />
    <v-card flat class="bottom-fixed">
      <v-card-title class="proposal-title justify-center">
        {{ proposal.descr }}
      </v-card-title>
      <v-card-text class="content" v-html="proposal.content"> </v-card-text>
      <v-card-actions class="flex-column mb-5">
        <v-btn
          v-if="$ebsn.meta(proposal, 'category_proposal_type.SHOW_BTN', false)"
          depressed
          block
          color="primary"
          class="mb-2"
          @click="skipTutorial()"
          :to="link"
        >
          {{
            $ebsn.meta(proposal, "category_proposal_type.BTN_TEXT", "Scopri")
          }}
        </v-btn>
        <v-btn depressed text block color="primary" @click="skipTutorial()"
          >Salta</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<style lang="scss">
.proposal-tutorial-slide {
  .bottom-fixed {
    position: fixed;
    background: transparent;
    bottom: 0px;
    width: 100%;
  }
  .proposal-img {
    top: 0px;
    position: fixed;
    width: 100%;
  }
  .proposal-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .content {
    font-size: 17px;
  }
  .proposal-title,
  .content {
    text-align: center;
    p {
      margin-bottom: 0px;
    }
  }
}
</style>
<script>
import banner from "~/mixins/banner";

import get from "lodash/get";

export default {
  name: "ProposalTutorialSlide",
  mixins: [banner],
  data() {
    return {};
  },
  methods: {
    skipTutorial() {
      this.$emit("skipTutorial");
    },
    getImage(proposal) {
      let prop =
        "metaData.category_proposal_type." +
        (!this.$vuetify.breakpoint.xsOnly ? "IMAGE_DESKTOP" : "IMAGE_MOBILE");
      return get(proposal, prop, proposal.img);
    }
  }
};
</script>
