import axios from "~/plugins/axios";

export default {
  login(username, password, otp) {
    let url = "/ebsn/api/backoffice-access/sso-login";
    // let params = { backoffice_user: username, password: password, otp: otp };
    let params = new URLSearchParams();
    params.append("backoffice_user", username);
    if (password) params.append("password", password);
    if (otp) params.append("otp", otp);

    return axios
      .post(url, params)
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          return Promise.reject();
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  logout() {
    let url = "/ebsn/api/backoffice-access/sso-logout";
    return axios
      .post(url)
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          return Promise.reject();
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  getCategoryBlock(categoryBlockId) {
    return axios
      .get("/ebsn/api/category-block/info", {
        params: {
          categoryBlockId: categoryBlockId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getCategoryBySlug(slug, skipCache, query) {
    let params = {
      slug: slug,
      skip_cache: skipCache
    };
    if (query) {
      params.q = query;
    }
    return axios
      .get("/ebsn/api/category", {
        params: params
      })
      .then(data => {
        return data.data.data.category;
      });
  },
  searchUser(q, page, pageSize, sort) {
    let params = {
      q: q,
      page: page,
      page_size: pageSize,
      sort : sort,
      user_type_id : 2
    };
    return axios
      .get("/ebsn/api/user-search/list_user", { params })
      .then(data => {
        return data.data;
      });
  },
  listUser() {
    return axios.get("/ebsn/api/backoffice-access/list_user").then(data => {
      return data.data;
    });
  },
  selectUser(userId) {
    let params = {
      user_id: userId
    };
    return axios
      .get("/ebsn/api/backoffice-access/select_user", { params })
      .then(data => {
        return data.data;
      });
  }
};
