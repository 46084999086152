<template>
  <div
    v-if="isBadgePresent"
    class="product-gift-certificates d-flex flex-column justify-space-around"
    :class="boxClass + ' ' + animationClass"
    @click.prevent.stop="handleClick"
    :style="{
      width: boxWidth + 'px',
      height: boxHeight + 'px',
      'animation-delay': Math.random() * 5 + 's'
    }"
    v-ripple
  >
    <div class="promo-tag badge-container font-weight-bold">
      <div v-if="badgeLabel" class="badge-tag">
        {{ badgeLabel }}
      </div>
      <!-- <img
        v-if="icon"
        class="badge-icon icon"
        :src="`img_layout/gift_certificates/${icon}`"
        :alt="badgeLabel"
      /> -->
      <v-icon v-if="icon" :alt="badgeLabel">
        ${{ icon.substring(0, icon.length - 4) }}
      </v-icon>
      <span v-else-if="badgeValue" class="d-block badge-value">
        {{ badgeValue }}<em class="small" v-html="badgeUnit"></em>
      </span>
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
      >
        <div v-if="isOpen" class="more-content">
          <span class="promo-body">
            {{ name }}
          </span>
          <div class="content" v-html="description"></div>
          <v-btn
            v-if="giftCertificate.giftCode && isOpen"
            class="btn-add"
            @click="add"
            elevation="0"
          >
            {{ $t("checkout.giftCodeUseNow") }}
          </v-btn>
          <v-btn
            v-if="link"
            class="btn-more"
            outlined
            :to="link"
            @click="closeWindow"
          >
            {{ btnLabel }}
          </v-btn>
        </div>
      </transition>
    </div>
  </div>
</template>
<style lang="scss">
$animation-duration: 0.4s;
@mixin color-modifiers {
  @each $name, $light, $base in $giftCertColors {
    &.#{$name} {
      color: #{$light};
      background-color: #{$base};
      border-color: #{$base};
    }
    &.is-closed {
      width: 80px;
    }
    &.is-open {
      &.#{$name} {
        // color: var(-v-grey-base) !important;
        border: 2px solid #{$base};
        .btn-add {
          background-color: #{$base} !important;
          color: #{$light};
          opacity: 1;
          width: 100% !important;
        }
        .btn-more {
          opacity: 1;
          border-color: #{$base} !important;
          color: #{$base} !important;
          background-color: transparent;
          width: 100% !important;
        }
        // .promo-body {
        //   color: #{$light};
        //   background-color: #{$base} !important;
        // }
      }
    }
  }
}

// .product-gift-certificates {
.certificate-box {
  transition: all $animation-duration ease-in-out;
  -webkit-transition: all $animation-duration ease-in-out;
  display: block;
  text-align: center;
  position: relative;
  border-radius: 10px 0;
  padding: 4px;
  min-width: 70px;
  // max-width: 75px;
  min-height: 48px;
  height: 50px;
  overflow: hidden;
  z-index: 4;
  user-select: none;
  margin-left: 2px;
  margin-bottom: 2px;
  .more-content {
    width: 200px;
    white-space: normal;
    margin: auto;
    margin-top: auto;
    margin-top: 16px;
    // visibility: hidden;
    &.animate__fadeIn {
      animation-delay: $animation-duration/2;
    }
  }
  .theme--light.v-icon {
    color: currentColor;
  }
  cursor: pointer;
  .promo-tag {
    line-height: 1;
    white-space: nowrap;
  }
  .badge-tag {
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
  }
  .badge-value {
    font-size: 30px;
    em {
      font-size: 18px;
    }
  }
  &.is-closed {
    width: 80px;
    position: block;
  }
  &.is-open {
    text-align: center;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    z-index: 5;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 400px;
    height: 100%;
    margin-top: -4px;
    margin-left: -4px;
    padding: 20px;
    border-radius: 4px;
    color: var(--v-default-base) !important;
    background-color: var(--v-grey-lighten1) !important;
    .promo-body {
      text-align: center;
      min-width: 100px;
      color: currentColor;
      padding: 8px;
      font-weight: bold;
      font-size: 18px;
    }
    .v-icon {
      color: currentColor;
    }
    .content {
      text-align: center;
      font-size: 12px;
      color: $text-color;
      font-weight: initial;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .small-text {
      display: none;
    }
  }
  @include color-modifiers;
  .small-text {
    font-size: 11px;
  }
  .promo-body {
    height: fit-content;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
}
.badge-container {
  text-align: center;
}
// }
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";
import GiftCertificateWindow from "./GiftCertificateWindow.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProposalGiftCard from "@/components/gift/ProposalGiftCard.vue";

import Vue from "vue";
import clickHandler from "~/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "ProductGiftCertificate",
  data() {
    return { isOpen: false, boxWidth: 80, boxHeight: 30 };
  },
  mixins: [clickHandler, giftCertificate],
  props: {
    containerRef: { type: Object, default: null },
    wrapperId: { type: String, default: null }
  },
  computed: {
    mode() {
      return get(
        this.giftCertificate,
        "categoryProposal.metaData.category_proposal_type.MODE",
        "badge"
      );
    },
    boxClass() {
      return [
        "certificate-box",
        this.isOpen ? "is-open" : "is-closed",
        this.cssClass
      ].join(" ");
    }
  },
  methods: {
    closeWindow() {
      this.isOpen = false;
    },
    handleClick() {
      let _this = this;
      if (this.mode == "link") {
        this.$router.push({
          name: "Category",
          params: { pathMatch: this.giftCertificate.categoryProposal.slug }
        });
      } else if (this.mode == "card") {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          this.boxWidth = this.$el.parentNode.parentNode.parentNode.clientWidth;
          this.boxHeight = this.$el.parentNode.parentNode.parentNode.clientHeight;
        } else {
          this.boxWidth = 80;
          this.boxHeight = 30;
          _this.$nextTick(() => {
            document.getElementById(_this.wrapperId).style.width = "80px";
          });
        }
      } else if (this.mode == "products") {
        //open popup with products
        new (Vue.extend(GiftCertificateWindow))({
          parent: this,
          propsData: {
            isOpen: true,
            giftCertificate: this.giftCertificate
          },
          components: {
            ProductListSlider,
            ProposalGiftCard
          },
          methods: {
            giftAdded: function() {
              this.$emit("add");
            }
          }
        }).$mount(this.$el.querySelector("#app"));
        // global.EventBus.$emit("open-product-list", {
        //   giftCertificate: this.giftCertificate
        // });
      }
    }
  }
};
</script>
