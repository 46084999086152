import deepMerge from "lodash/merge";
import clone from "lodash/cloneDeep";
import get from "lodash/get";
import mapKeys from "lodash/mapKeys";

export default {
  computed: {
    optionsName() {
      return this.categoryBlockName
        ? this.categoryBlockName
        : this.$options.name;
    },
    swiperOption() {
      let keyMap = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1264,
        xl: 1900
      };

      let jsonConfig = deepMerge(
        this.swiperDefaultOption,
        clone(
          get(
            this.config,
            `metaData.categoryblocktype_${this.optionsName}.JSON_CONFIG`
          )
        )
      );

      let convertedBreakpoints = mapKeys(jsonConfig.breakpoints, function(
        // eslint-disable-next-line no-unused-vars
        value,
        key
      ) {
        return keyMap[key];
      });
      if (convertedBreakpoints) jsonConfig.breakpoints = convertedBreakpoints;

      return jsonConfig;
    }
  },
  methods: {
    getProposalModelName(proposal) {
      return get(proposal, "categoryProposalType.name", "ProposalCard");
    }
  }
};
