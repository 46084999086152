<template>
  <div>
    <label>{{ data.label }}</label>
    <v-select
      outlined
      v-model="selected"
      :items="
        data.options
          ? data.options.map(prop => ({ text: prop.label, value: prop.value }))
          : null
      "
      :menu-props="{ maxHeight: '400' }"
      :label="data.label"
      multiple
      @change="update"
    ></v-select>
  </div>
</template>
<script>
export default {
  name: "NinjaFormsMultipleSelect",
  props: {
    data: { type: Object }
  },
  data() {
    return {
      selected: []
    };
  },
  created() {
    this.data.value = [];
  },
  methods: {
    update() {
      this.data.value = this.selected;
    }
  }
};
</script>
