import BannerGrid from "./BannerGrid.vue";
import BannerSlider from "./BannerSlider.vue";
import CrmProductSlider from "./CrmProductSlider.vue";
import ProductSlider from "./ProductSlider.vue";
import ServiceDiscovery from "./ServiceDiscovery.vue";
import RecipeSlider from "./RecipeSlider.vue";
import StaticImage from "./StaticImage.vue";
import StaticText from "./StaticText.vue";
import Tutorial from "./Tutorial.vue";
import ArticleList from "./ArticleList.vue";
import ArticleCardList from "./ArticleCardList.vue";
import ArticleSlider from "./ArticleSlider.vue";
import FormDetail from "./FormDetail.vue";
import GiftCodeWidgetSlider from "./GiftCodeWidgetSlider.vue";
import CartInfoCardWrapper from "@/components/cart/CartInfoCardWrapper";
import LeafletViewerWrapper from "./LeafletViewerWrapper";
import LeafletSliderWrapper from "./LeafletSliderWrapper";
import { VRow, VCol, VContainer } from "vuetify/lib";

import kebabCase from "lodash/kebabCase";
import get from "lodash/get";
import { setStylesBasedOnBreakpoints } from "@/components/category/templateHelper";

export default {
  install: app => {
    app.component("page-grid-internal", {
      functional: true,
      name: "pageGrid",
      props: {
        category: { type: Object, required: true },
        cssClass: { type: String | Array, required: false }
      },

      render: function(createElement, context) {
        const { category, cssClass } = context.props;

        let rowsConfig = category?.metaData?.template_model?.GRID_CONFIG.rows;
        let rows = rowsConfig.map((row, ir) => {
          let vRow = createElement(
            VRow,
            { class: `grid-row row-number-${ir}` },
            row.cols.map((col, ic) => {
              return createElement(
                VCol,
                {
                  props: {
                    cols: col.xs || 12,
                    sm: col.sm,
                    md: col.md,
                    lg: col.lg,
                    xl: col.xl
                  },
                  class: [
                    "grid-column",
                    "h-100",
                    "col-number-" + ic,
                    `row-${ir}-col-${ic}`
                  ]
                },
                col.categoryBlocks.map((categoryBlockConfig, ip) => {
                  return createElement("category-block", {
                    props: {
                      target: category,
                      position: categoryBlockConfig.position,
                      container: categoryBlockConfig.container || false,
                      key: ic,
                      categoryBlockStyle: categoryBlockConfig.style
                    },
                    class:
                      "category-block h-100 multiple-col col-num-" +
                      ir +
                      ic +
                      ip
                  });
                })
              );
            })
          );
          if (row.container) {
            return createElement(
              VContainer,
              {
                class: ["container-number-" + ir]
              },
              [vRow]
            );
          } else {
            return vRow;
          }
        });

        let div = createElement(
          "div",
          {
            class: ["page-grid", cssClass]
          },
          rows
        );

        return div;
      }
    });
    app.component("category-block", {
      functional: true,
      name: "category-block",
      props: {
        target: {
          type: Object,
          required: false
        },
        position: {
          type: String,
          required: false
        },
        config: {
          type: Object,
          required: false
        },
        container: {
          type: Boolean,
          default: true
        },
        productId: {
          type: Number,
          required: false
        },
        categoryId: {
          type: Number,
          required: false
        },
        categoryBlockStyle: {
          type: Object,
          required: false
        }
      },
      render: function(createElement, context) {
        let cfg;
        if (context.props.config) {
          cfg = context.props.config;
        } else if (context.props.position) {
          cfg = context.props.target?.categoryBlocks?.find(
            categoryBlock =>
              categoryBlock.templateBlock.codInt == context.props.position
          );
        } else {
          cfg = context.props.target?.categoryBlocks?.[0];
        }
        if (cfg) {
          // const cmp = defineAsyncComponent({
          //   loader: () => import("@/components/categoryBlock/BannerSlider.vue")
          // });
          let cmp;

          //   = () =>
          //     import(
          //       `@/components/categoryBlock/${cfg.templateBlockType.modeName}.vue`
          //     );
          switch (cfg.templateBlockType.modeName) {
            case "BannerGrid":
              cmp = BannerGrid;
              break;
            case "BannerSlider":
              cmp = BannerSlider;
              break;
            case "CrmProductSlider":
              cmp = CrmProductSlider;
              break;
            case "ProductSlider":
              cmp = ProductSlider;
              break;
            case "StaticImage":
              cmp = StaticImage;
              break;
            case "StaticText":
              cmp = StaticText;
              break;
            case "Tutorial":
              cmp = Tutorial;
              break;
            case "ServiceDiscovery":
              cmp = ServiceDiscovery;
              break;
            case "RecipeSlider":
              cmp = RecipeSlider;
              break;
            case "ArticleList":
              cmp = ArticleList;
              break;
            case "ArticleCardList":
              cmp = ArticleCardList;
              break;
            case "ArticleSlider":
              cmp = ArticleSlider;
              break;
            case "GiftCodeWidgetSlider":
              cmp = GiftCodeWidgetSlider;
              break;
            case "FormDetail":
              cmp = FormDetail;
              break;
            case "CartInfoCardWrapper":
              cmp = CartInfoCardWrapper;
              break;
            case "LeafletViewerWrapper":
              cmp = LeafletViewerWrapper;
              break;
            case "LeafletSliderWrapper":
              cmp = LeafletSliderWrapper;
              break;
          }

          if (cmp) {
            const id = `category-block-${cfg.categoryBlockId}`;
            const componentName = kebabCase(cfg.templateBlockType.modeName);
            let content = [];
            let style = app.$ebsn.meta(
              cfg,
              `categoryblocktype_${cfg.templateBlockType.modeName}.CSS_STYLE`
            );
            if (style) {
              let value = style.replace(
                /([^\r\n,{};(?!@)]+)(,|{)/g,
                `#${id}` + " " + "$1$2"
              );
              content.push(
                createElement(
                  "style",
                  {
                    attrs: {
                      type: "text/css"
                    }
                  },
                  value
                )
              );
            }
            content.push(
              createElement(cmp, {
                props: {
                  id: id,
                  config: cfg,
                  container: context.props.container,
                  productId: context.props.productId,
                  categoryId: context.props.categoryId
                },
                style: createStyle(cfg, cfg.templateBlockType.modeName)
              })
            );
            if (context.props.container) {
              content = createElement("div", { staticClass: "container" }, [
                content
              ]);
            }
            let paramClass = " ";
            if (context.data.attrs?.class) {
              paramClass += context.data.attrs.class + " ";
            }
            if (context.data.attrs?.staticClass) {
              paramClass += context.data.attrs.staticClass + " ";
            }
            if (context.data.class) {
              paramClass += context.data.class + " ";
            }
            if (context.data.staticClass) {
              paramClass += context.data.staticClass + " ";
            }

            // set specific style for the category block
            let ebsnStyle;
            if (context.props.categoryBlockStyle) {
              ebsnStyle = createElement("ebsnStyle", {
                props: {
                  target: {
                    metaData: {
                      css: setStylesBasedOnBreakpoints(
                        context.props.categoryBlockStyle,
                        `#${id}`
                      )
                    }
                  },
                  path: "css"
                }
              });
            }

            return createElement(
              "div",
              {
                attrs: {
                  id: id
                },
                key: context.data.key,
                staticClass: `${componentName} ${id} ${paramClass}`,
                on: {
                  hide: function(event, a) {
                    console.log(event);
                    console.log(a);
                  }
                }
              },
              [ebsnStyle, content]
            );
          }
        }
        return null;
        function createStyle(config, optionsName) {
          let style = {};
          let backgroundColor = get(
            config,
            `metaData.categoryblocktype_${optionsName}.BACKGROUND_COLOR`
          );
          if (backgroundColor) {
            style.backgroundColor = backgroundColor;
          }
          return style;
        }
      }
    });
  }
};
