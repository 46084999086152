<template>
  <div
    class="tutorial"
    v-if="isCordova && config.proposals && config.proposals.length > 0"
  >
    <v-dialog
      v-model="showTutorial"
      :fullscreen="$vuetify.breakpoint.mobile"
      transition="dialog-bottom-transition"
      class="tutorial-dialog"
    >
      <v-btn
        @click="closeTutorial()"
        icon
        small
        class="vuedl-layout__closeBtn close"
        :aria-label="$t('common.close')"
      >
        <v-icon>$close</v-icon>
      </v-btn>
      <ProposalTutorialSlider
        :proposals="carousel"
        @skipTutorial="closeTutorial"
      />
    </v-dialog>
  </div>
</template>
<style lang="scss">
.tutorial-dialog {
  .vuedl-layout__closeBtn.close {
    position: absolute;
    opacity: 1;
    z-index: 1000;
    margin-top: -8px;
    cursor: pointer;
  }
}
</style>
<script>
// import BannerSlider from "@/components/categoryBlock/BannerSlider.vue";
import ProposalTutorialSlider from "@/components/tutorial/ProposalTutorialSlider.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

// import CategoryService from "~/service/categoryService";

import get from "lodash/get";
import { mapActions, mapState } from "vuex";

export default {
  name: "Tutorial",
  components: { ProposalTutorialSlider },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      carousel: [],
      showTutorial: false
    };
  },
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },

  computed: {
    ...mapState({
      lastTutorialSeen: state => state.app.lastTutorialSeen
    })
  },
  methods: {
    ...mapActions({
      updateLastTutorialSeen: "app/updateLastTutorialSeen"
    }),
    closeTutorial() {
      this.showTutorial = false;
      this.updateLastTutorialSeen(this.tutorialId);
    }
  },
  mounted() {
    try {
      if (this.config.proposals && this.config.proposals.length > 0) {
        this.tutorialId = get(this.config, "proposals[0].id");
        this.carousel = this.config.proposals;
        this.showTutorial =
          this.carousel &&
          this.carousel.length > 0 &&
          this.tutorialId != this.lastTutorialSeen;
      }
    } catch (e) {
      console.log(e);
    }
  }
};
</script>
