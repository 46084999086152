<template>
  <div>
    <label>{{ data.label }}</label>
    <v-radio-group v-model="data.value" :rules="rules">
      <v-radio
        v-for="option in data.options"
        :key="option.key"
        :label="option.label"
        :value="option.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>
<script>
export default {
  name: "NinjaFormsListCheckbox",
  props: ["rules", "data"],
  mounted() {
    this.data.value = null;
  }
};
</script>
