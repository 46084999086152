<template>
  <v-card
    outlined
    class="article-card align-start"
    :class="size"
    :to="{ name: article.articleType.name, params: { pageName: article.slug } }"
  >
    <ebsn-media
      :cover="true"
      width="100%"
      :height="imgHeight"
      :max-height="imgHeight"
      :target="article"
      type="Article main"
      media="thumb"
      :alt="article.title"
    />
    <template v-if="size != 'small'">
      <v-chip
        v-if="article.articleType.name == 'recipe'"
        color="primary"
        class="article-course ml-4"
      >
        {{ course }}
      </v-chip>
      <div v-if="article.articleType.name == 'post'" class="d-flex">
        <v-chip
          v-for="category in categories"
          :key="category.id"
          color="primary"
          class="article-course ml-4"
        >
          {{ category.name }}
        </v-chip>
      </div>
    </template>
    <v-card-subtitle
      v-if="article.articleType.name == 'post' && !isCardInHomePage"
      class="small--text"
    >
      {{ $dayjs(article.pubDate, "DD/MM/YYYY").format("DD MMMM YYYY") }}
    </v-card-subtitle>
    <v-card-title
      class="font-weight-bold text-left w-100 d-flex justify-start align-start article-title"
      >{{ article.title }}</v-card-title
    >
    <v-card-text v-if="size != 'small'">
      <div
        class="is-content"
        v-if="article.content"
        v-html="article.content"
      ></div>
      <template v-else>
        {{ article.contentAbstract }}
      </template>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.article-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  .article-title {
    line-height: 1.4rem !important;
  }
  .article-course {
    margin-top: -16px;
  }
  &.small {
    .v-card__title {
      font-size: 14px;
      font-weight: normal;
    }
  }
  p {
    font-size: 14px;
    padding-bottom: 0;
  }
}
</style>
<script>
export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      default: "normal"
    }
  },
  computed: {
    isCardInHomePage() {
      return this.$route.path === "/";
    },
    imgHeight() {
      if (this.size == "small") {
        return 100;
      } else {
        return 200;
      }
    },
    course() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == "course"
      )?.name;
    },
    categories() {
      return this.article?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  }
};
</script>
