<template>
  <div class="address-selector fill-height">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-layout fill-height column>
      <div class="d-none d-sm-flex justify-center">
        <img class="logo" :src="serviceImg" height="60" alt="Logo Coop Shop" />
      </div>
      <h3 class="mb-sm-0 text-center" v-if="category">
        {{ category.name }}
      </h3>
      <p
        class="selector-subitle mb-1 text-center"
        v-html="category.description"
        v-if="category"
      ></p>

      <v-row
        v-if="deliveryServices.length"
        no-gutters
        justify="space-between"
        align="center"
        class="my-1 my-sm-3 tabs"
      >
        <v-col
          cols="12"
          sm="6"
          :md="deliveryServiceButtonsLength"
          v-for="(service, idx) in deliveryServices"
          :key="service.deliveryServiceId"
          @click="fetchAddresses(service.deliveryServiceId, idx)"
        >
          <div
            class="tab d-flex flex-row flex-md-column align-center justify-center flex-grow-1 pa-1 mx-1 mb-1 mb-md-0"
            :class="
              service.deliveryServiceId === selectedDeliveryServiceId
                ? `${service.cssClass} selected`
                : `${service.cssClass}`
            "
          >
            <v-icon color="black" large class="mx-3 mx-md-0">{{
              "$" + $t(`navbar.service.${service.deliveryServiceId}.fontIcon`)
            }}</v-icon>
            <div
              class="d-flex flex-column align-start align-md-center justify-start flex-grow-1"
            >
              <h5>{{ service.name }}</h5>
              <div class="service-descr text-left text-md-center">
                {{ service.descr }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card :loading="loading" flat>
        <div class="w-100 address-list pt-0">
          <v-radio-group v-model="selectedShippingAddressId">
            <v-radio
              v-for="address in filteredAddresses"
              :key="address.addressId"
              @click="setAddress(address)"
              :value="address.addressId"
              color="default"
              class="pa-3"
            >
              <template v-slot:label>
                <div class="address-name font-weight-bold w-100">
                  {{ address.addressName }}
                </div>
                <div class="text-body-2">
                  {{
                    $t(
                      "navbar.address." + address.addressTypeId + ".list",
                      address
                    )
                  }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <div class="d-flex justify-end">
            <v-btn
              v-if="isHomeDelivery"
              color="primary"
              large
              text
              :to="{ name: 'EditAddress' }"
              @click="$emit('submit', false)"
              ><v-icon left>
                $plus
              </v-icon>
              {{ $t("addresses.addAddressButton") }}
            </v-btn>
          </div>
        </div>
      </v-card>
      <v-card-actions class="justify-start">
        <v-btn
          color="primary"
          large
          outlined
          depressed
          @click="openStoreSelector"
        >
          {{ $t("addressSelector.changeCooperative") }}
        </v-btn>
      </v-card-actions>
    </v-layout>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.address-selector {
  h3 {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      line-height: 2;
      font-size: 16px;
    }
  }
  .tab {
    border: 1px solid var(--v-grey-lighten2);
    transition: border-color 0.3s ease;
    border-radius: 8px;
    background-color: var(--v-grey-lighten2);
    min-height: 110px;
    cursor: pointer;
    &.selected {
      background-color: var(--v-primary-base);
      border-color: var(--v-primary-base);
      color: $white !important;
      .v-icon {
        color: $white !important;
      }
    }
    h5 {
      font-weight: 600;
      margin: 0;
    }
    .service-descr {
      font-size: 12px;
    }
  }
  .tab:hover {
    border-color: var(--v-primary-base);
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    .tab {
      min-height: 70px;
    }
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .tab {
      min-height: 58px;
    }
  }
  .address-list {
    overflow-y: auto;
    min-height: 200px;
    max-height: calc(100vh - 505px);
    padding: 2px;
    .v-label {
      flex-direction: column;
      align-items: start;
    }
    .v-btn {
      border: none;
    }
    .v-radio {
      border-bottom: 1px solid var(--v-grey-base);
      margin-bottom: 0;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-height: 200px;
      max-height: calc(70vh - 200px);
      height: auto;
    }
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import categoryMixin from "~/mixins/category";

import StoreService from "~/service/storeService";
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

export default {
  name: "AddressSelector",
  mixins: [cartInfo, categoryMixin],
  data() {
    return {
      store: {},
      addresses: [],
      loading: null,
      selectedShippingAddressId: null,
      selectedDeliveryServiceId: null,
      tab: 2,
      searchText: null,
      category: {},
      showAddAddress: false
    };
  },
  computed: {
    deliveryServiceButtonsLength() {
      return 12 / this.deliveryServices.length;
    },
    filteredAddresses() {
      if (this.searchText) {
        return this.addresses.filter(address => {
          return (
            address.addressName
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.address1
              .toLowerCase()
              .indexOf(this.searchText.toLowerCase()) > -1 ||
            address.city.toLowerCase().indexOf(this.searchText.toLowerCase()) >
              -1
          );
        });
      } else {
        return this.addresses;
      }
    },
    ...mapState({
      cart: ({ cart }) => cart.cart,
      selectedService: ({ cart }) => cart.selectedService
    }),
    deliveryServices() {
      if (this.store && this.store.deliveryServices) {
        return this.store.deliveryServices.filter(button => {
          return button.showTab;
        });
      }
      return [];
    },
    isHomeDelivery() {
      let homeIds = [2, 6, 10];
      return homeIds.includes(this.selectedDeliveryServiceId);
    }
  },
  methods: {
    async remove(address) {
      let _this = this;
      const res = await _this.$dialog.confirm({
        text: global.vm.$t("message.removeAddress")
      });
      if (res) {
        let response = await DeliveryService.removeAddress(address.addressId);
        _this.addresses = response.addresses;
      }
    },
    edit(address) {
      let _this = this;
      _this.$router.push({
        path: "/profile/addresses/manage/" + address.addressId
      });
      _this.$emit("submit", true);
    },
    async fetchStore() {
      let _this = this;
      let store = await StoreService.getStoreById(_this.cart.store.storeId);
      _this.store = store;

      _this.store.deliveryServices.find(deliveryService => {
        if (
          deliveryService.deliveryServiceId ==
          _this.cart.shippingAddress.deliveryServiceId
        ) {
          _this.selectedDeliveryServiceId = deliveryService.deliveryServiceId;
        }
      });
    },
    async fetchAddresses(deliveryServiceId) {
      let _this = this;
      _this.loading = true;
      _this.searchText = null;
      _this.selectedDeliveryServiceId = deliveryServiceId;
      _this.addresses = await StoreService.getAddressesByDeliveryService(
        _this.selectedDeliveryServiceId
      );
      if (
        !this.cart.shippingAddress.autoAssigned &&
        this.cart.shippingAddress.deliveryServiceId == deliveryServiceId
      ) {
        this.selectedShippingAddressId = this.cart.shippingAddress.addressId;
      }
      _this.loading = false;
    },
    async setAddress(address) {
      let _this = this;
      this.loading = true;
      let data = await this.$store.dispatch("cart/setAddress", {
        addressType: address.addressType,
        addressId: address.addressId
      });

      this.loading = false;
      if (data) {
        _this.$emit("submit", true);
        setTimeout(function() {
          _this.$router.push({ name: "Home" });
        }, 200);
      }
    },

    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("service");
      } catch (e) {
        console.log(e);
      }
    },
    resetFields() {
      this.showAddAddress = !this.showAddAddress;
    },
    async openStoreSelector() {
      this.$emit("submit", "setStore");
    }
  },
  created() {
    this.fetchCategory();
    this.fetchStore();
    this.fetchAddresses(this.cart.shippingAddress.deliveryServiceId);
    if (!this.cart.shippingAddress.autoAssigned) {
      this.selectedShippingAddressId = this.cart.shippingAddress.addressId;
    }
  }
};
</script>
