import get from "lodash/get";
import forOwn from "lodash/forOwn";

function mapPersonInfo(mapping, personKey) {
  let _person = personKey || "person";
  let mappedClass = {};
  forOwn(mapping, function(key, value) {
    mappedClass[value] = {
      get() {
        let person = get(this, _person);
        return getPersonInfo(person, key);
      },
      set(value) {
        let person = get(this, _person);
        return setPersonInfo(person, key, value);
      }
    };
  });
  return mappedClass;
}
function mapCartInfo(mapping) {
  let mappedClass = {};
  forOwn(mapping, function(key, value) {
    mappedClass[value] = {
      get() {
        let value = get(this.$store.state.cart.cart, "cartInfos." + key);
        return value;
      },
      set(value) {
        let payload = {};
        payload[key] = value || null;
        this.$store.dispatch("cart/setCartInfo", payload);
      }
    };
  });
  return mappedClass;
}
function mapProp(mapping, objectKey) {
  let mappedClass = {};
  forOwn(mapping, function(key, value) {
    mappedClass[value] = function() {
      if (objectKey) {
        return get(this[objectKey], key);
      } else {
        return get(this, key);
      }
    };
  });
  return mappedClass;
}
function mapProposal(mapping, categoryKey) {
  let _category = categoryKey || "category";
  let mappedClass = {};
  forOwn(mapping, function(key, value) {
    mappedClass[value] = function() {
      return get(this, _category + ".proposals." + key, []);
    };
  });
  return mappedClass;
}
function mapProposalComponent(mapping, categoryKey) {
  let _category = categoryKey || "category";
  let mappedClass = {};
  forOwn(mapping, function(key, value) {
    mappedClass[`${value}Proposals`] = function() {
      return get(this, _category + ".proposals." + key, () => []);
    };
    mappedClass[`${value}Mode`] = function() {
      return get(
        this,
        `${_category}.metaData.template_model.${key.toUpperCase()}_MODE`,
        "ProposalSlider"
      );
    };
    mappedClass[`${value}Title`] = function() {
      return get(
        this,
        `${_category}.metaData.template_model.${key.toUpperCase()}_TITLE`
      );
    };
  });
  return mappedClass;
}
function getPersonInfo(person, personInfoTypeId) {
  if (!person || !person.personInfos) {
    return null;
  }
  for (let i = 0; i < person.personInfos.length; i++) {
    if (person.personInfos[i].personInfoTypeId == personInfoTypeId) {
      return person.personInfos[i].value;
    }
  }
}
function setPersonInfo(person, personInfoTypeId, personInfoValue) {
  if (!person.personInfos) {
    person.personInfos = new Array();
  }
  let found = false;
  for (let i = 0; i < person.personInfos.length; i++) {
    if (person.personInfos[i].personInfoTypeId == personInfoTypeId) {
      person.personInfos[i].value = personInfoValue;
      found = true;
      break;
    }
  }
  if (!found) {
    person.personInfos.push({
      personInfoTypeId: personInfoTypeId,
      value: personInfoValue
    });
  }
}
function forEachCartItem(object, callback) {
  if (!object) {
    return;
  }
  if (object.sectors) {
    for (let i = 0; i < object.sectors.length; i++) {
      if (forEachCartItem(object.sectors[i], callback)) return;
    }
  } else if (object.suborders) {
    for (let i = 0; i < object.suborders.length; i++) {
      if (forEachCartItem(object.suborders[i], callback)) return;
    }
  } else if (object.cartItems) {
    if (forEachCartItem(object.cartItems, callback)) return;
  } else if (object.suborderItems) {
    if (forEachCartItem(object.suborderItems, callback)) return;
  } else if (object.length) {
    for (let i = 0; i < object.length; i++) {
      try {
        if (callback(object[i])) return;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
function filterProductsInCart(products, cart) {
  let filteredProducts = [];
  for (let f = 0; f < products.length; f++) {
    let product = products[f];
    let found = false;
    forEachCartItem(cart, function(item) {
      if (item.product.productId == product.productId) {
        found = true;
      }
    });
    if (!found) {
      filteredProducts.push(product);
    }
  }
  return filteredProducts;
}
function handleReceiptDownload(url, fileName) {
  // eslint-disable-next-line no-unused-vars
  var ref;
  // eslint-disable-next-line no-undef
  if (device.platform === "iOS") {
    if (window.cordova && window.cordova.InAppBrowser) {
      ref = window.cordova.InAppBrowser.open(
        url,
        "_system",
        "footer=no,location=no,toolbar=yes,zoom=no,hardwareback=no,enableViewportScale=yes"
      );
    }
  } else {
    window.requestFileSystem(
      // LocalFileSystem.PERSISTENT,
      window.TEMPORARY,
      0,
      function(fs) {
        fs.root.getFile(
          fileName + ".pdf",
          { create: true, exclusive: false },
          function(fileEntry) {
            console.log("fileEntry is file? " + fileEntry.isFile.toString());
            fileEntry.createWriter(
              function(fileWriter) {
                var oReq = new XMLHttpRequest();
                oReq.open("GET", url, true);
                oReq.responseType = "arraybuffer";
                oReq.onload = function() {
                  var blob = new Blob([oReq.response], {
                    type: "application/pdf"
                  });
                  if (blob) {
                    fileWriter.write(blob);

                    fileWriter.onwriteend = function() {
                      var urlFile = fileEntry.toURL();
                      if (urlFile.indexOf("file://") === -1) {
                        urlFile = fileEntry.nativeURL;
                      }
                      window.cordova.plugins.fileOpener2.showOpenWithDialog(
                        urlFile,
                        "application/pdf",
                        {
                          error: function error(err) {
                            console.error(err);

                            global.EventBus.$emit("error", {
                              error: err,
                              code: "errors.errorNoappAvailable"
                            });
                          },
                          success: function success() {
                            console.log("success with opening the file");
                          },
                          position: [0, 0]
                        }
                      );
                    };

                    fileWriter.onerror = function(err) {
                      global.EventBus.$emit("error", {
                        error: err,
                        code: "errors.errorLoadingInvoice"
                      });
                      console.error(err);
                    };
                  } else console.error("we didnt get an XHR response!");
                };
                oReq.send(null);
              },
              function(err) {
                console.error(err);
              }
            );
          },
          function(err) {
            console.error("error getting file! " + err);
          }
        );
      },
      function(err) {
        console.error("error getting persistent fs! " + err);
      }
    );
  }
}
function stripHtml(originalString) {
  if (originalString != undefined) {
    let strippedString = originalString.replace(/(<([^>]+)>)/gi, "");
    return strippedString;
  }
  return "";
}
function replaceHtmlEntities(originalString) {
  if (originalString != undefined) {
    return originalString.replace(/&#(\d+);/g, function(match, dec) {
      return String.fromCharCode(dec);
    });
  }
  return "";
}
function runScript(category) {
  // SEMPRE ATTENZIONE che la function sia una IIFE (wrappata e autoinvocata).
  // Verificare che prettier-ignore funzioni e non vengano rimosse le parentesi
  // prettier-ignore
  if (category?.metaData?.category_advanced?.ACTIONS){
      (Function(category.metaData.category_advanced.ACTIONS))(); 
    }
}

export {
  mapPersonInfo,
  mapCartInfo,
  mapProposal,
  mapProposalComponent,
  getPersonInfo,
  setPersonInfo,
  forEachCartItem,
  filterProductsInCart,
  handleReceiptDownload,
  stripHtml,
  replaceHtmlEntities,
  mapProp,
  runScript
};
