const routes = [
  {
    path: "/page/:pageName",
    name: "page",
    component: () => import("@/views/cms/Page.vue")
  },
  {
    path: "/faq/:pageName",
    name: "faq",
    component: () => import("@/views/cms/Page.vue")
  },
  {
    path: "/ricette/:pageName",
    name: "recipe",
    meta: {
      breadCrumb: "Ricette"
    },
    component: () => import("@/views/cms/Recipe.vue")
  },
  {
    path: "/blog/:pageName",
    name: "post",
    meta: {
      breadCrumb: "Blog"
    },
    component: () => import("@/views/cms/Blog.vue")
  },
  {
    path: "/cm-search",
    name: "CmSearch",
    meta: { slug: "cm-search" },
    component: () => import("@/views/cms/CmSearch.vue")
  },
  {
    path: "/catalogo-soci/:leafletId",
    name: "catalogo-soci",
    meta: {
      breadCrumb: "Catalogo Soci"
    },
    component: () => import("@/views/leaflet/LeafletView.vue")
  },
  {
    path: "/volantini/:leafletId",
    name: "volantini",
    meta: {
      breadCrumb: "Dettaglio Volantino"
    },
    component: () => import("@/views/leaflet/LeafletView.vue")
  },
  {
    path: "/shop-at-work",
    name: "ShopAtWorkSubscription",
    props: route => ({
      code: route.params.code
    }),
    component: () => import("@/views/cms/ShopAtWorkSubscription.vue"),
    meta: { requiresAuth: true, slug: "shop-at-work" }
  }
];

export default routes;
