<template>
  <div class="article-list">
    <h3>{{ title }}</h3>
    <v-list>
      <v-list-item
        class="article-list-item py-2 px-0"
        v-for="item in articleList"
        :key="item.articleId"
        :to="{ name: item.articleType.name, params: { pageName: item.slug } }"
      >
        <v-list-item-avatar tile size="120" class="my-0 mr-2">
          <ebsn-media
            :cover="true"
            width="100%"
            :target="item"
            type="Article main"
            media="thumb"
            :alt="'Naviga ' + item.title"
            :aria-label="'Naviga ' + item.title"
            onerror="this.src='/no-icon.png'"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          {{ item.title }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.article-list {
  .article-list-item {
    border-bottom: 1px solid var(--v-grey-lighten3);
  }
}
</style>
<script>
export default {
  name: "ArticleAccordionList",
  props: {
    title: {
      type: String,
      required: false
    },
    articleList: {
      type: Array,
      required: true
    }
  }
};
</script>
