<template>
  <div class="article-list">
    <article-card-list
      :title="$ebsn.meta(config, 'categoryblocktype_ArticleList.TITLE')"
      :article-list="articleList"
    />
  </div>
</template>
<script>
import ArticleCardList from "@/components/article/ArticleCardList.vue";

import cmService from "~/service/mksCmService";

export default {
  components: {
    ArticleCardList
  },
  name: "ArticleCardListBlock",
  data() {
    return {
      articleList: [],
      pager: null,
      articleClasses: []
    };
  },
  props: { config: { type: Object, required: true } },
  methods: {
    async reload() {
      let parentArticleTypeId = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleCardList.ARTICLE_TYPE"
      );
      let parentArticleClassId = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleCardList.ARTICLE_CLASS_FILTER"
      );
      let limit = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleCardList.LIMIT",
        4
      );
      let sort = this.$ebsn.meta(
        this.config,
        "categoryblocktype_ArticleCardList.ARTICLE_SORT"
      );
      let params = {
        parent_article_type_id: parentArticleTypeId,
        parent_article_class_id: parentArticleClassId,
        page_size: limit,
        sort: sort
      };

      let content = await cmService.searchArticle(params);
      this.articleList = content?.data?.articles;
      this.pager = content?.data?.page;
      this.articleClasses = content?.data?.facets;
      this.key++;
    }
  },
  created() {
    this.reload();
  }
};
</script>
