<template functional>
  <div class="product_promo" :class="props.warehousePromo.view.cssClass">
    <div class="promo_header" v-html="props.warehousePromo.view.header"></div>
    <div class="promo_body" v-html="props.warehousePromo.view.body"></div>
    <div class="promo_footer" v-html="props.warehousePromo.view.footer"></div>
  </div>
</template>
<script>
export default {
  name: "ProductPromo",
  props: {
    warehousePromo: { type: Object, required: true }
  }
};
</script>
