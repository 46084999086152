<template functional>
  <div class="product-qty">
    <div
      class="qty-wrap"
      :class="{
        'not-empty': parent.quantity > 0,
        'show-add-btn': parent.quantity < 1
      }"
      @mousedown.stop
    >
      <v-btn
        large
        aria-label="Diminuisci quantità"
        depressed
        class="minus rounded"
        @click.stop.prevent="
          parent.minus(
            props.updateCart,
            { infos: { ...props.selectedOptions } },
            {
              searchUid: props.product.searchUid,
              position: props.position
            }
          )
        "
      >
        <v-icon>$minus</v-icon>
      </v-btn>
      <div class="val-cnt" @click.stop.prevent="" @mousedown.stop>
        <span class="val">{{ parent.quantity }}{{ parent.unit }}</span>
        <span class="small">{{ parent.quantityPerUnit }}</span>
      </div>
      <v-btn
        large
        aria-label="Aumenta quantità"
        class="plus rounded"
        depressed
        @click.stop.prevent="
          parent.plus(
            props.updateCart,
            {
              infos: {
                ...props.selectedOptions
              }
            },
            {
              searchUid: props.product.searchUid,
              position: props.position
            }
          )
        "
      >
        <v-icon v-if="parent.quantity > 0">$plus</v-icon>
        <template v-else>
          <v-icon>$cart</v-icon>
          <span
            v-if="props.label"
            class="font-weight-bold white--text text-uppercase ml-2"
          >
            {{ props.label }}
          </span>
        </template>
      </v-btn>
    </div>

    <!-- <v-btn
      depressed
      class="add-to-cart-btn"
      height="50"
      min-width="50"
      color="primary"
      v-if="props.showCartButton"
      @click.stop.prevent="
        parent.addToCart(parent.quantity, { infos: { ...selectedOptions } })
      "
    >
      <v-icon>$cart</v-icon>
      <span v-if="props.label" class="ml-2">{{ props.label }}</span>
    </v-btn> -->
  </div>
</template>

<script>
export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined }
  }
};
</script>
