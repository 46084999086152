import base from "./base";
// import wordpress from "./wordpress";
import warehouse from "./warehouse";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";
import cms from "./cms";
// import store from "./store";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      slug: "homepage-2023",
      canonical: "/"
    },
    component: () => import("@/views/catalog/Category.vue")
  },
  ...base,
  // ...wordpress,
  ...profile,
  ...checkout,
  ...warehouse,
  ...cms,
  // ...store,
  ...catalog
];

export default routes;
