/* eslint-disable no-debugger */
import PushNotificationService from "~/service/pushNotificationService";
import { mapGetters } from "vuex";
var _this;
export default {
  ...mapGetters({
    isAuthenticated: "cart/isAuthenticated"
  }),
  data() {
    return {
      deviceToken: null
    };
  },
  methods: {
    handlePush(notification) {
      // console.log("::: HANDLEPUSH FIREBASE ::::: ", notification);
      // console.log("Message type: " + notification.messageType);
      // console.log("Tapped in " + notification.tap);
      if (notification) {
        if (notification.aps && notification.aps.alert) {
          //iOS - NAV TO
          if (notification && notification.tap === "background") {
            if (notification.nav_to) {
              global.vm.$router.push(notification.nav_to);
            }
          } else {
            global.EventBus.$emit("notification", {
              title: notification.aps.alert.title,
              message: notification.body,
              callback: value => {
                if (value) {
                  if (notification.nav_to) {
                    global.vm.$router.push(notification.nav_to);
                  }
                }
              }
            });
          }
        } else if (notification.title) {
          // ANDROID
          if (notification && notification.tap === "background") {
            if (notification.nav_to) {
              global.vm.$router.push(notification.nav_to);
            }
          } else {
            global.EventBus.$emit("notification", {
              title: notification.title,
              message: notification.body,
              callback: value => {
                if (value) {
                  if (notification.nav_to) {
                    global.vm.$router.push(notification.nav_to);
                  }
                }
              }
            });
          }
        }
      }
    },
    async unRegisterFirebaseDevice() {
      console.log("::: FIREBASE ::::: UNREGISTER FIREBASE START");
      if (window.FirebasePlugin) {
        window.FirebasePlugin.unregister();
        // eslint-disable-next-line no-undef
        PushNotificationService.unRegister(device);
      }
    },
    async registerFirebaseDevice() {
      console.log("::: FIREBASE ::::: REGISTER FIREBASE START");
      if (window.FirebasePlugin) {
        window.FirebasePlugin.grantPermission(
          function() {
            console.log("::: FIREBASE ::::: Push granted");
          },
          function() {
            console.log("::: FIREBASE ::::: Push not granted");
          }
        );
        window.FirebasePlugin.onMessageReceived(_this.handlePush);
        // TODO GPA: Da Testare onMessageReceived su iOS in Foreground
        // window.FirebasePlugin.onMessageReceived(
        //   function(message) {
        //     console.log(
        //       "::: FIREBASE ::::: Message type: " + message.messageType
        //     );
        //     if (message.messageType === "notification") {
        //       console.log("::: FIREBASE ::::: Notification message received");
        //       if (message.tap) {
        //         console.log("::: FIREBASE ::::: Tapped in " + message.tap);
        //       }
        //     }
        //     console.dir(message);
        //   },
        //   function(error) {
        //     console.error(error);
        //   }
        // );
        window.FirebasePlugin.getToken(
          function(token) {
            // eslint-disable-next-line no-undef
            PushNotificationService.register(token, device);
            _this.deviceToken = token;
            window.FirebasePlugin.subscribe("all");
          },
          function(error) {
            console.error("::::: ERROR GET TOKEN :::: ", error);
          }
        );

        window.FirebasePlugin.onTokenRefresh(
          function(token) {
            if (_this.isAuthenticated) {
              // eslint-disable-next-line no-undef
              PushNotificationService.register(token, device);
              _this.deviceToken = token;
            }
          },
          function(error) {
            console.error("::: ERROR FIREBASE :::::", error);
          }
        );
      }
    }
  },
  mounted() {
    _this = this;
  }
};
