<template functional>
  <div class="product-price d-flex justify-center ml-2">
    <span
      class="max-purchasable smaller"
      v-if="props.product.productInfos.MAXACQ"
    >
      max {{ props.product.productInfos.MAXACQ }}
    </span>
    <div
      class="old-price"
      v-if="
        props.product.priceStandardDisplay &&
          props.showStandardPrice &&
          props.product.priceStandardDisplay > 0.01
      "
    >
      {{
        parent.$n(
          $options.confezione(
            props.product.productInfos.TIPOLOGIA,
            props.product.priceStandardDisplay,
            props.product.productInfos.WEIGHT_SELLING
          ),
          "currency"
        )
      }}
      <span
        v-if="props.product.productInfos.TIPOLOGIA == 'Sfuso'"
        class="unit-price"
        >/{{ props.product.weightUnitDisplay }}
      </span>
    </div>
    <div class="d-flex justify-space-between align-end price-container">
      <div class="cur-price">
        {{
          parent.$n(
            $options.confezione(
              props.product.productInfos.TIPOLOGIA,
              props.product.priceDisplay,
              props.product.productInfos.WEIGHT_SELLING
            ),
            "currency"
          )
        }}
        <span
          v-if="props.product.productInfos.TIPOLOGIA == 'Sfuso'"
          class="unit-price"
          >/{{ props.product.weightUnitDisplay }}
        </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.product-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: fit-content;

  .card {
    position: absolute;
    bottom: 86px;
    left: 120px;
    img {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.85);
      width: 46px;
      margin-bottom: 3px;
    }
  }

  .max-purchasable {
    display: none;
  }
  .cur-price {
    text-align: left;
    font: normal normal 600 24px/26px $body-font-family;
    letter-spacing: 0px;
    white-space: nowrap;
    .unit-price {
      font-size: 1rem;
      text-transform: lowercase;
      position: relative;
      left: -5px;
    }
  }
  .old-price {
    font: normal normal 600 16px/17px $body-font-family;
    letter-spacing: 0px;
    text-decoration: line-through;
    white-space: nowrap;
    padding: 4px 3px 0 3px;
    .unit-price {
      font-size: 10px;
      text-transform: lowercase;
    }
  }
}
.is-cordova {
  .price-wraper {
    justify-content: flex-start !important;
  }
  .product-price {
    width: 100% !important;
  }
  .product-card {
    .iva {
      margin-bottom: 2px;
    }
  }
}
.checkout {
  .cart-item .has-promo {
    .product-price {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        position: absolute;
        bottom: 38px;
        left: 2px;
      }
    }
  }
  .accept-alternatives {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-top: 8px !important;
    }
  }
}
.horizontal-crm .product_promo .bubble {
  top: 0px;
  left: -68px;
  width: 65px;
  right: initial;
}
</style>
<script>
import toNumber from "lodash/toNumber";
export default {
  name: "props.productPrice",
  props: {
    product: { type: Object, required: true },
    showStandardPrice: { type: Boolean, default: true }
  },
  confezione(tiplogia, price, weightUnit) {
    if (tiplogia == "Confezione") {
      return (price * 1000) / (1000 / toNumber(weightUnit, 1)) / 1000;
    } else {
      return price;
    }
  }
};
</script>
