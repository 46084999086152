<template>
  <div
    :id="`static-text-${config.categoryBlockId}`"
    class="static-text"
    :style="style"
  >
    <div :class="{ container: container }" @click="clicked">
      <ebsn-meta
        :target="config"
        path="categoryblocktype_StaticText.TITLE"
        tag="h2"
      />
      <v-runtime-template v-if="template" :template="template" />
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";

import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "StaticText",
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    NinjaForm
  },
  mixins: [clickHandler],
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },

  computed: {
    template() {
      if (this.config) {
        return Vue.$ebsn.meta(
          this.config,
          "categoryblocktype_StaticText.DESCRIPTION"
        );
      } else {
        return null;
      }
    },
    style() {
      let style = {};
      let backgroundColor = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_StaticText.BACKGROUND_COLOR"
      );
      if (backgroundColor) {
        style.backgroundColor = backgroundColor;
      }
      let backgroundImage = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_StaticText.BACKGROUND_IMAGE.location"
      );
      if (backgroundImage) {
        style.backgroundImage = `url("${backgroundImage}")`;
        style.backgroundPosition = "top center";
        style.paddingTop = "180px";
      }
      return style;
    }
  }
};
</script>
