<template functional>
  <div class="title-row d-flex flex-column flex-sm-row align-sm-center">
    <ebsn-meta
      :target="props.config"
      :path="`categoryblocktype_${props.categoryBlockName}.TITLE`"
      tag="h2"
      cssClass="slider-title mr-3"
    />
    <transition
      enter-active-class="animate__animated animate__fadeInLeft20"
      leave-active-class="animate__animated animate__slideOutLeft"
    >
      <router-link
        v-if="
          props.showMore &&
            !parent.$ebsn.meta(
              props.config,
              'categoryblocktype_' + props.categoryBlockName + '.HIDE_LINK'
            )
        "
        v-show="props.animateLink"
        color="primary"
        depressed
        class="show-more-link align-self-end align-self-md-center mx-3"
        :to="
          $options.calcLinkToGo(
            props.config,
            props.categoryBlockName,
            parent.$ebsn.meta
          )
        "
        :aria-label="`Vai al ${props.categoryBlockName}`"
        :title="`Vai al ${props.categoryBlockName}`"
      >
        {{
          parent.$ebsn.meta(
            props.config,
            "categoryblocktype_" + props.categoryBlockName + ".BUTTON_TEXT",
            parent.$t("categoryBlock.productListSlider.showAll")
          )
        }}

        <v-icon small class="chevron-link-icon">$chevronRight</v-icon>
      </router-link>
    </transition>
  </div>
</template>
<style lang="scss">
.chevron-link-icon {
  opacity: 0;
}
.product-list-slider:hover {
  .chevron-link-icon {
    opacity: 1;
  }
}
.title-row {
  .slider-title {
    font-weight: 600;
  }
  .show-more-link {
    font-weight: bold;
    // color: var(--v-secondary-base);
  }
}
</style>
<script>
export default {
  name: "TitleRow",
  props: {
    config: { type: Object, required: true },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false },
    animateLink: { type: Boolean, default: false }
  },
  calcLinkToGo(config, categoryBlockName, getMetaFunction) {
    let categorySlug = getMetaFunction(
      config,
      "categoryblocktype_" + categoryBlockName + ".CATEGORY.slug"
    );
    if (categorySlug) {
      return {
        name: "Category",
        params: {
          pathMatch: categorySlug
        },
        query: {
          categoryId: getMetaFunction(
            config,
            "categoryblocktype_" +
              categoryBlockName +
              ".SUB_CATEGORY.categoryId"
          )
        }
      };
    } else {
      return getMetaFunction(
        config,
        "categoryblocktype_" + categoryBlockName + ".LINK"
      );
    }
  }
};
</script>
