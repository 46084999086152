<template>
  <v-dialog
    v-model="isOpen"
    transition="dialog-bottom-transition"
    content-class="gift-certificate-window"
  >
    <ProductListSlider
      :proposal="giftCertificate.categoryProposal"
      mode="gift"
      cols="auto"
      sm="auto"
      md="auto"
      lg="auto"
      xl="auto"
      v-intersect.once="handleView"
      @selectProduct="handleClick"
    >
      <template #firstCard>
        <ProposalGiftCard
          :giftCertificate="giftCertificate"
          @add="giftAdded"
          @submit="isOpen = false"
        />
      </template>
    </ProductListSlider>
  </v-dialog>
</template>
<style lang="scss">
.gift-certificate-window {
  align-self: flex-end;
  width: 100%;
  margin: 0;
  padding: 10px;
  .description {
    width: 200px;
  }
}
</style>
<script>
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import ProposalGiftCard from "@/components/gift/ProposalGiftCard.vue";
import analyticsService from "@/commons/service/analyticsService";
export default {
  components: { ProductListSlider, ProposalGiftCard },
  name: "GiftCertificateWindow",
  data() {
    return {
      isOpen: true
      // giftCertificate: {}
    };
  },
  props: { giftCertificate: { type: Object, required: true } },
  methods: {
    giftAdded() {
      this.isOpen = false;
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, "Gift Certificate");
      }
    },

    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        "Gift Certificate",
        payload.index
      );
    }
  },
  mounted() {
    global.EventBus.$on("open-product-list", data => {
      this.isOpen = true;
      this.giftCertificate = data.giftCertificate;
    });
  }
};
</script>
