import CatalogService from "./catalogService";
import OrderService from "./orderService";
import axios from "~/plugins/axios";
import { forEachCartItem } from "~/service/ebsn.js";

function getDefaultParams() {
  return {
    // store_id: global.config.defaultStoreId || 5,
    show_sectors: global.config.showSectors
  };
}
function getCart() {
  return axios
    .get("/ebsn/api/cart/view", { params: { ...getDefaultParams() } })
    .then(data => {
      return data.data.data;
    })
    .catch(() => {
      return Promise.reject();
    });
}

function buy(args) {
  var params = { ...getDefaultParams() };

  if (args) {
    if (args.userPaymenthAuthId) {
      params.user_payment_auth_id = args.userPaymenthAuthId;
    }
    if (args.paymentTypeId) {
      params.payment_type_id = args.paymentTypeId;
    }
    if (args.paymentData) {
      params.payment_data = JSON.stringify(args.paymentData);
    }
  }

  return axios
    .post("/ebsn/api/checkout/buy", null, { params: params })
    .then(data => {
      if (data.data.response.status == 0) {
        return data.data.data;
      } else {
        throw new Error(data.data.response.errors[0].error);
      }
    });
}

export default {
  getCart: getCart,
  buy: buy,
  isLoggedIn(operation) {
    return axios
      .get("/ebsn/api/auth/test", {
        params: { ...getDefaultParams(), operation: operation }
      })
      .then(data => {
        return data.data && data.data.user.userId > -1;
      })
      .catch(() => {
        return false;
      });
  },
  addProduct(product, quantity, params, searchParams) {
    var items = [
      {
        product: product,
        quantity: quantity,
        params: params,
        searchParams: searchParams ? { ...searchParams } : undefined
      }
    ];
    return this.addProducts(items);
  },
  addProducts(items) {
    let params = {};
    params.items = [];

    for (var i = 0; i < items.length; i++) {
      let product = items[i].product;

      let decodedQuantity = CatalogService.encodeProductQuantity(
        product,
        items[i].quantity
      );
      items[i].originalQuantity = items[i].quantity;
      items[i].quantity = decodedQuantity;

      let item = {
        productId: product.productId,
        quantity: decodedQuantity,
        ...items[i].params,
        searchParams: items[i].searchParams
          ? { ...items[i].searchParams }
          : undefined
      };

      if (product.deliveryWarehouse) {
        item["deliveryWarehouseId"] = product.deliveryWarehouse.warehouseId;
      }

      //creo parametro select options
      let optionArray = [];
      for (
        let x = 0;
        product.selectOptions && x < product.selectOptions.length;
        x++
      ) {
        if (product.selectOptions[x].selected) {
          let option = product.selectOptions[x];
          optionArray.push(option.name + ": " + option.selected.key);
        }
      }
      if (!item.infos) item.infos = {};
      // if (!item.infos.notes) {
      //   item.infos.notes = optionArray.join(", ");
      // }

      if (global.config.acceptAlternativesOnPromo != undefined) {
        if (product.warehousePromo != undefined) {
          item.infos.accept_alternatives =
            global.config.acceptAlternativesOnPromo;
        } else {
          item.infos.accept_alternatives =
            global.config.acceptAlternatives != undefined
              ? global.config.acceptAlternatives
              : true;
        }
      } else {
        //STANDARD
        item.infos.accept_alternatives =
          global.config.acceptAlternatives != undefined
            ? global.config.acceptAlternatives
            : true;
      }
      //creo custom parameter based on selectOption
      //calculate additional parameters based on selectedOptions
      // for (let j = 0; j < product.selectOptions.length; j++) {
      //   let option = product.selectOptions[j];
      //   if (option.parameterName && option.selected && option.selected.value) {
      //     item.infos[option.parameterName] = option.selected.value;
      //   }
      // }
      params.items.push(item);
    }
    return axios
      .post("/ebsn/api/cart/add", params, {
        params: { ...getDefaultParams() }
      })
      .then(data => {
        //show toaster..

        if (
          data &&
          data.data &&
          data.data.response &&
          data.data.response &&
          data.data.response.status === 0
        ) {
          // console.log(data.data);

          // const lastItemId = data.data.lastCartItem.itemId;

          // const

          //  AnalyticsService.addToCart(items);

          for (let i = 0; i < items.length; i++) {
            // const name = items[i].product.name;
            const fullName = CatalogService.getProductFullnameForToast(
              items[i].product
            );

            const quantity = items[i].originalQuantity;

            // const viewQuantity = CatalogService.getProductQuantityForToast(
            //   items[i]
            // );
            const unit = CatalogService.getProductUnitForToast(
              items[i].product
            );
            if (
              data?.data?.response?.errorsMessage.length === 0 &&
              data?.data?.response?.infosMessage.length === 0 &&
              data?.data?.response?.warningsMessage.length === 0
            ) {
              global.EventBus.$emit("success", {
                message: global.vm.$t("cart.productAddText", {
                  quantity,
                  unit,
                  name: fullName
                })
              });
            }
          }
        }
        return data.data.data;
      });
  },
  allProductsFromOrder(orderId) {
    return axios
      .post("/ebsn/api/cart/add-all-from-order", null, {
        params: {
          order_id: orderId,
          ...getDefaultParams()
        }
      })
      .then(data => {
        //show toaster..
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("cart.productsAddFromOrder")
        });
        return data.data.data;
      });
  },
  allProductsFromWishlist(listId) {
    return axios
      .post("/ebsn/api/cart/add-all-from-wishlist", null, {
        params: {
          list_id: listId,
          ...getDefaultParams()
        }
      })
      .then(data => {
        //show toaster..
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("cart.productsAddFromOrder")
        });
        return data.data.data;
      });
  },

  /**
   * * PROMO GIFT SECTION
   */
  sendGiftCode(giftCode) {
    return axios
      .get("/ebsn/api/cart-giftcertificate/check", {
        params: {
          code: giftCode,
          ...getDefaultParams()
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  addGiftCode(giftCodeId) {
    return axios
      .post(
        "/ebsn/api/cart-giftcertificate/add_code",
        {},
        {
          params: {
            code: giftCodeId,
            ...getDefaultParams()
          }
        }
      )
      .then(data => {
        //show toaster..
        // global.EventBus.$emit("success", {
        //   message: global.vm.$t("cart.sendGiftCode", {
        //     name: giftCode
        //   })
        // });
        return data.data.data;
      });
  },
  removeGiftCode(giftCodeId) {
    return axios
      .post(
        "/ebsn/api/cart-giftcertificate/remove_code",
        {},
        {
          params: {
            code: giftCodeId,
            ...getDefaultParams()
          }
        }
      )
      .then(data => {
        return data.data.data;
      });
  },
  setItem(item, quantity, searchParams) {
    //calcolo la quantity corretta in base al tipo di prodotto
    var encodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      quantity
    );

    var params = {
      items: [
        {
          itemId: item.itemId,
          quantity: encodedQuantity,
          searchParams: { ...searchParams }
        }
      ]
    };
    return axios
      .post("/ebsn/api/cart/update", params, {
        params: { ...getDefaultParams() }
      })
      .then(data => {
        // const viewQuantity = CatalogService.getProductQuantityForToast(item);
        const unit = CatalogService.getProductUnitForToast(item.product);
        const fullName = CatalogService.getProductFullnameForToast(
          item.product
        );

        if (
          data?.data?.response?.status === 0 &&
          data?.data?.response?.errorsMessage.length === 0 &&
          data?.data?.response?.infosMessage.length === 0 &&
          data?.data?.response?.warningsMessage.length === 0
        ) {
          global.EventBus.$emit("success", {
            message: global.vm.$t("cart.productAddText", {
              quantity,
              unit,
              name: fullName
            })
          });
        }

        return data.data.data;
      });
  },
  removeItem(item) {
    let params = {
      items: [
        {
          itemId: item.itemId,
          productId: item.product.productId
        }
      ]
    };
    return axios
      .post("/ebsn/api/cart/delete", params, {
        params: { ...getDefaultParams() }
      })
      .then(data => {
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("cart.productRemoved")
        });
        return data.data.data;
      });
  },
  emptyCart(cart) {
    let items = [];
    forEachCartItem(cart, function(item) {
      items.push({ itemId: item.itemId, productId: item.product.productId });
    });
    // var params = { items: items };

    let params = {
      items: items
    };
    return axios
      .post("/ebsn/api/cart/delete", params, {
        params: { ...getDefaultParams() }
      })
      .then(data => {
        global.EventBus.$emit("success", {
          message: global.EventBus.$t("cart.productsRemoved")
        });
        return data.data.data;
      });
  },
  plus(product, qty, ignoreMultiplier) {
    let total;
    if (product) {
      if (
        product.productInfos.TIPOLOGIA == "Pezzo" ||
        product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        let step =
          product.productInfos.QTY_MULT && !ignoreMultiplier
            ? parseFloat(product.productInfos.QTY_MULT)
            : 1;
        let remainder = qty % step;
        total = qty ? qty - remainder + step : step;
        if (product.minAcq) {
          total = total >= product.minAcq ? total : product.minAcq;
        }
        // if (product.maxAcq) {
        //   total = total <= product.maxAcq ? total : product.maxAcq;
        // }
        // if (product.available) {
        //   total = total <= product.available ? total : product.available;
        // }
        return this.round(total);
      }
      if (product.productInfos.TIPOLOGIA == "Sfuso") {
        total = qty
          ? qty + parseFloat(product.productInfos.STEP_SELLING)
          : parseFloat(product.productInfos.WEIGHT_SELLING);

        return this.round(total);
      }
    }
  },
  minus(product, qty, ignoreMultiplier) {
    let total;
    if (
      product.productInfos.TIPOLOGIA == "Pezzo" ||
      product.productInfos.TIPOLOGIA == "Confezione"
    ) {
      let step =
        product.productInfos.QTY_MULT && !ignoreMultiplier
          ? parseFloat(product.productInfos.QTY_MULT)
          : 1;
      let remainder = qty % step;
      if (remainder > 0) {
        total = qty - remainder;
      } else {
        total = qty ? qty - step : 0;
      }
      if (product.minAcq) {
        total = total >= product.minAcq ? total : product.minAcq;
      }
      return this.round(total);
    }
    if (product.productInfos.TIPOLOGIA == "Sfuso") {
      total = qty ? qty - parseFloat(product.productInfos.STEP_SELLING) : 0;

      total = this.round(total);
      return total >= parseFloat(product.productInfos.WEIGHT_SELLING)
        ? total
        : 0;
    }
  },
  round(total) {
    var totalReturn = (total * 1000).toFixed(0);
    return totalReturn / 1000;
  },
  lockCart(params) {
    return axios
      .post("/ebsn/api/checkout/prepare", null, {
        params: {
          ...params,
          ...getDefaultParams()
        }
      })
      .then(data => {
        if (data.data.response.status === 0) {
          return data.data.data;
        } else {
          throw new Error(data.data.response.errors[0].error);
          //Promise.reject(new Error("errore"));
        }
      });
  },
  // getPaymentTypeList(orderId) {
  //   return axios
  //     .get("/ebsn/api/payment-type/list", { params: { order_id: orderId } })
  //     .then(data => {
  //       return data.data.data.payments;
  //     });
  // },
  getSatispayFeedback(url, paramsObj) {
    return axios.get(url, { params: paramsObj }).then(
      function(data) {
        return data;
      },
      function() {
        return [];
      }
    );
  },
  getPreBilledAmount(paymentTypeId, orderId) {
    return axios
      .get("/ebsn/api/checkout/prebilled-amount", {
        params: {
          payment_type_id: paymentTypeId,
          order_id: orderId
        }
      })
      .then(function(data) {
        return data.data.data.prebilledAmount;
      });
  },
  getPackages() {
    return axios.get("/ebsn/api/packaging-type/list").then(data => {
      return data.data.data.products;
    });
  },
  getPaymentAuthList(paymentTypeId) {
    return axios
      .get("/ebsn/api/payment-type/list-auth", {
        params: { payment_type_id: paymentTypeId }
      })
      .then(data => {
        return data.data.data.auths;
      });
  },
  confirmOrderAndTrack(paymentTypeId, userPaymenthAuthId, paymentData) {
    return buy(paymentTypeId, userPaymenthAuthId, paymentData);
  },
  async modifyOrderAndTrack(
    orderId,
    paymentTypeId,
    userPaymenthAuthId,
    paymentData
  ) {
    const oldOrder = await OrderService.getOrder(orderId, true);
    // OrderService.getOrder(orderId, true)
    //   .then(oldOrder => {
    const buyresponse = await buy(
      paymentTypeId,
      userPaymenthAuthId,
      paymentData,
      oldOrder
    );
    return buyresponse;
    //     .then(data => {
    //       return data;
    //     })
    //     .catch(data => {
    //       return Promise.reject(data);
    //     });
    // })
    // .catch(data => {
    //   return Promise.reject(data.data);
    // });
  },
  setCartInfo(name, value) {
    let params = {
      property: name,
      value: value
    };
    return axios
      .post("/ebsn/api/cart/update-cart-info", params, {
        params: { ...getDefaultParams() }
      })
      .then(data => {
        return data.data.data;
      });
  },
  setCartItemInfo(items, name, value) {
    var params = { items: [] };
    if (items instanceof Array) {
      items.forEach(addItemToParams);
    } else {
      addItemToParams(items);
    }
    return axios
      .post("/ebsn/api/cart/update-infos", params, {
        params: { ...getDefaultParams() }
      })
      .then(data => {
        return data.data.data;
      });

    function addItemToParams(item) {
      let param = { itemId: item.itemId, infos: {} };
      param.infos[name] = value;
      params.items.push(param);
    }
  },
  getPurchaseRestriction() {
    return axios.get("/ebsn/api/cart/purchase-restriction").then(data => {
      return data.data.data;
    });
  },
  getAmount(item) {
    if (item.product.productInfos.TIPOLOGIA == "Sfuso") {
      //Handling floating point decimals issues
      var val = (item.weight * 100).toFixed(0);
      val = val * CatalogService.getMultiplier(item.product);
      val = val / 100;
      return val;
    } else {
      return item.quantity;
    }
  }
};
