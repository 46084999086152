<template>
  <div
    class="leaflet-container"
    id="leaflet-viewer"
    ref="leafletViewer"
    v-if="leaflet"
  >
    <v-bottom-navigation
      id="leaflet-toolbar"
      dense
      flat
      background-color="primary lighten-2"
      color="primary"
      class="elevation-0"
    >
      <!-- <v-toolbar-title>
        <h2>{{ leaflet.title }}</h2>
        <div>
          Dal
          {{ leaflet.fromDate | dayjs("format", "D  MMMM") }} al
          {{ leaflet.toDate | dayjs("format", "D  MMMM") }}
        </div>
      </v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-slide-group multiple show-arrows>
        <v-btn
          v-if="leaflet"
          icon
          color="primary"
          target="blank"
          width="80"
          :href="`${leaflet.baseLocation}${leaflet.location}`"
          @click.prevent="
            handleReceiptDownload(
              `${leaflet.baseLocation}${leaflet.location}`,
              leaflet.title
            )
          "
        >
          <span class="primary--text text--darken-1">{{
            $t("leaflet.navigation.download")
          }}</span>
          <v-icon color="primary darken-1">$download</v-icon>
        </v-btn>

        <v-btn
          width="80"
          icon
          color="primary"
          depressed
          @click.stop="toogleFullscreen"
        >
          <span class="primary--text text--darken-1">{{
            $t("leaflet.navigation.zoom")
          }}</span>
          <v-icon color="primary darken-1">$fullscreen</v-icon>
        </v-btn>
      </v-slide-group>
    </v-bottom-navigation>
    <leaflet-viewer
      :key="leafletId"
      :leaflet="leaflet"
      :fullscreen="leafletFullscreen"
      :swiperHeight="swiperHeight"
      @slideChanged="slideChanged"
    />
  </div>
</template>
<style lang="scss">
#leaflet-toolbar {
  .v-toolbar__title {
    h2 {
      font-size: 20px;
      line-height: 1.3;
    }
    div {
      font-size: 12px;
    }
  }
  .v-btn__content {
    color: var(--v-primary-base);
    span {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
  .v-item-group {
    margin-right: 12px;
  }
  &.v-item-group.v-bottom-navigation .v-btn {
    min-width: 70px;
  }
  .v-menu__content {
    max-width: 100%;
    left: 0 !important;
    right: 0;
    width: 100%;
  }
}
.leaflet-container {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide-active {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
  }
  .swiper-slide-next {
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }

  .swiper-slide img {
    display: block;
    max-width: 100%;
    // height: 100%;
    object-fit: contain;
    max-height: inherit;
    height: 100%;
  }
  .imgage-container {
    box-sizing: content-box;
  }

  .swiper-slide-zoomed {
    z-index: 2;
  }

  @media only screen and (min-width: 769px) {
    .swiper-slide:first-child {
      transition: transform 100ms;
    }

    .swiper-slide:first-child img {
      transition: box-shadow 500ms;
    }

    .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(50%);
      z-index: 2;
    }

    .swiper-slide.swiper-slide-active:first-child img {
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.35);
    }
    .swiper-slide:not(:first-child) img {
      box-shadow: 6px 6px 12px 0px rgba(0, 0, 0, 0.35);
    }

    .swiper-slide:nth-child(2) {
      transition: transform 100ms;
    }

    .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(55%);
      z-index: 1;
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-active:first-child {
      transform: translateX(-50%);
    }

    .swiper[dir="rtl"] .swiper-slide.swiper-slide-next:nth-child(2) {
      transform: translateX(-55%);
    }
    .swiper-slide-active {
      -webkit-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
    }
  }

  .category-leaflet-btn {
    .v-btn__content {
      flex-direction: column;
      .category-block-title {
        font-size: 10px;
        font-weight: normal;
        text-transform: none;
        white-space: initial;
        max-width: 70px;
      }
    }
  }
  .leaflet-page-container {
    position: relative;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
  }

  .draggable {
    cursor: pointer;
    position: absolute;
    &:hover {
      background-color: rgba(255, 221, 2, 0.35);
      border: 1px solid rgba(255, 221, 2, 1);
      transition: all 0.2s;
    }
  }
}
.leaflet-dialog-fullscreen {
  display: flex;
  align-items: center;
  background: var(--v-grey-lighten2);
  .exit-fullscreen-btn {
    position: absolute;
    z-index: 2;
    right: 10px;
  }
  .v-bottom-navigation {
    position: relative;
    bottom: 0px;
  }
  .swiper-pagination {
    position: fixed;
    bottom: 5px;
  }
  .leaflet-container {
    height: 100%;
  }
}
</style>
<script>
import LeafletViewer from "@/components/leaflet/LeafletViewer.vue";

import clickHandler from "~/mixins/clickHandler";
import downloadDocumentMixin from "~/mixins/downloadDocument";
import get from "lodash/get";

import { mapState } from "vuex";
import isNumber from "lodash/isNumber";

import leafletService from "~/service/leafletService";

const header = { fullscreenHeader: 97, mobileHeader: 280, desktopHeader: 300 };

export default {
  // eslint-disable-next-line vue/no-unused-components
  name: "Leaflet",
  components: {
    LeafletViewer
  },
  props: {
    leafletByProps: {
      type: Object,
      required: false
    },
    leafletId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      leaflet: {},
      leafletFullscreen: false,
      curentPage: 0,
      swiperHeight: this.$vuetify.breakpoint.mdAndUp
        ? window.innerHeight - header.desktopHeader + "px"
        : window.innerHeight - header.mobileHeader + "px"
    };
  },
  mixins: [clickHandler, downloadDocumentMixin],
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    vBreadcrums() {
      // let b = this.breadcrumb;
      let b = [];
      b.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      b.push({
        to: {
          name: "Leaflets"
        },
        text: "Volantini",
        exact: true
      });
      if (this.leaflet) {
        b.push({
          to: {
            name: "Leaflet",
            params: {
              volantino: this.$route.params.volantino
            }
          },
          text: this.leaflet.title,
          exact: true
        });
      }

      return b;
    }
  },
  methods: {
    toogleFullscreen() {
      const _this = this;
      if (_this.leafletFullscreen) {
        document.exitFullscreen().then(function() {
          if (_this.$vuetify.breakpoint.mdAndUp) {
            _this.swiperHeight =
              window.innerHeight - header.desktopHeader + "px";
            console.log(_this.swiperHeight);
          } else {
            _this.swiperHeight =
              window.innerHeight - header.mobileHeader + "px";
            console.log(_this.swiperHeight);
          }
        });
      } else {
        // this.leafletFullscreen = true;
        let elem = this.$refs.leafletViewer;
        var methodToBeInvoked =
          elem.requestFullscreen ||
          elem.webkitRequestFullScreen ||
          elem["mozRequestFullscreen"] ||
          elem["msRequestFullscreen"];
        let _this = this;
        if (methodToBeInvoked)
          methodToBeInvoked.call(elem).then(function() {
            _this.swiperHeight =
              window.screen.height - header.fullscreenHeader + "px";
            console.log(_this.swiperHeight);
          });
      }
    },
    setLeaflet(leaflet) {
      this.leaflet = leaflet;
    },
    async slideChanged(index) {
      this.curentPage = index;
      if (window.innerWidth > 769 && index != 0) {
        index = [index, index + 1];
      }
    },
    async fetchLeaflet() {
      console.log("fetchLeaflet", this.leafletId);
      if (this.leafletId) {
        this.leaflet = await leafletService.getLeafletById(this.leafletId);
      }
    },
    exitHandler() {
      let attr =
        document.fullscreen === true ||
        document.webkitIsFullScreen === true ||
        document.mozFullScreen === true ||
        document.msFullscreenElement === true;

      if (attr === false) {
        this.leafletFullscreen = false;
      } else if (attr === true) {
        this.leafletFullscreen = true;
      }
    }
  },
  metaInfo() {
    return {
      title: get(this.leaflet, "title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(this.leaflet, "description")
        }
      ]
    };
  },
  mounted() {
    console.log("mountedede");
    if (this.leafletByProps) {
      this.leaflet = this.leafletByProps;
    } else if (this.leafletId) {
      this.fetchLeaflet();
    }

    let elem = this.$refs.leafletViewer;
    elem.addEventListener("fullscreenchange", this.exitHandler, false);
    elem.addEventListener("mozfullscreenchange", this.exitHandler, false);
    elem.addEventListener("MSFullscreenChange", this.exitHandler, false);
    elem.addEventListener("webkitfullscreenchange", this.exitHandler, false);
    console.log(this.swiperHeight);
  },
  beforeDestroy() {
    let elem = this.$refs.leafletViewer;
    elem.removeEventListener("fullscreenchange", this.exitHandler);
    elem.removeEventListener("mozfullscreenchange", this.exitHandler);
    elem.removeEventListener("MSFullscreenChange", this.exitHandler);
    elem.removeEventListener("webkitfullscreenchange", this.exitHandler);
  },
  watch: {
    "$route.params.leafletId": async function() {
      if (isNumber(this.$route.params.leafletId)) {
        this.slug = this.$route.params.leafletId;
      } else {
        this.leafletId = this.$route.params.leafletId;
      }
      this.fetchLeaflet();
    }
  }
};
</script>
