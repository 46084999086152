<template>
  <div
    class="recipe-slider-container"
    v-if="
      !loading &&
        this.config &&
        this.config.proposals &&
        this.config.proposals.length > 0
    "
  >
    <TitleRow
      :config="config"
      :categoryBlockName="'RecipeSlider'"
      :showMore="true"
    />

    <div class="slider news-slider">
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-for="proposal in config.proposals" :key="proposal.id">
          <ProposalImage
            :proposal="proposal"
            :position="config.templateBlock.codInt"
          />
        </swiper-slide>
      </swiper>
      <swiper-paginator
        :showBullets="
          $ebsn.meta(
            config,
            'categoryblocktype_RecipeSlider.SHOW_BULLETS',
            true
          )
        "
        :showArrows="
          $ebsn.meta(
            config,
            'categoryblocktype_RecipeSlider.SHOW_ARROWS',
            false
          )
        "
        :paginationClass="config.categoryBlockId"
        :length="config.proposals.length"
      />
    </div>
  </div>
</template>
<style lang="scss">
.recipe-slider {
  .swiper-slide {
    height: auto;
    .proposal-card-overlay.v-card {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import TitleRow from "@/components/categoryBlock/elements/TitleRow.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";

import CMService from "~/service/cmService";
import SwiperPaginator from "../SwiperPaginator.vue";

export default {
  name: "RecipeSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    TitleRow,
    SwiperPaginator
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      loading: true,
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-${this.config.categoryBlockId}`,
          nextEl: `#next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
      if (!this.config.proposals || this.config.proposals.length < 1) {
        this.$emit("hide");
      }
    },
    async fetchRecipe() {
      this.loading = true;

      let params = [
        {
          filterName: "page",
          filterValue: 1
        }
      ];

      let res = await CMService.getCustomPostByFilters(
        "recipe",
        params,
        this.$ebsn.meta(
          this.config,
          "categoryblocktype_RecipeSlider.RECIPE_LIMIT",
          8
        )
      );

      if (res && res.data && res.data.length) {
        this.config.proposals = [];
        for (let i = 0; i < res.data.length; i++) {
          this.config.proposals.push({
            id: res.data[i].id,
            categoryProposalType: { name: "ProposalImage" },
            slug: "",
            priority: 1,
            name: "",
            descr: res.data[i].title.rendered,
            content: "",
            img: res.data[i].featured_image_url,
            imgAlt: "sconto fedelta",
            imgDescription: "sconto fedelta",
            categoryProposedId: -1,
            clickable: false,
            link: "",
            proposalUrl: "/news/" + res.data[i].slug,
            proposalUrlType: "",
            metaData: {
              category_proposal_type: {
                TEXT_COLOR: this.$ebsn.meta(
                  this.config,
                  "categoryblocktype_RecipeSlider.TEXT_COLOR",
                  "bottom bottom-left"
                ),
                TEXT_POSITION: this.$ebsn.meta(
                  this.config,
                  "categoryblocktype_RecipeSlider.TEXT_POSITION",
                  "bottom bottom-left"
                )
              }
            }
          });
        }
      }

      this.loading = false;
    }
  },
  mounted() {
    this.fetchRecipe();
  },
  created() {
    this.$emit("hide");
  }
};
</script>
