<template>
  <ProductListSlider
    :config="config"
    :products="products"
    :categoryBlockName="'ProductSlider'"
    :showMore="showMore"
    v-intersect.once="handleView"
    @selectProduct="handleClick"
  />
</template>

<script>
import ProductListSlider from "./elements/ProductListSlider.vue";

// import categoryBlockType from "./categoryBlockType";
import deliveryReactive from "~/mixins/deliveryReactive";

import ProductService from "~/service/productService";
import analyticsService from "~/service/analyticsService";
import get from "lodash/get";

import Vue from "vue";

export default {
  name: "ProductSlider",
  props: {
    config: {
      type: Object,
      required: true
    },
    container: {
      type: Boolean,
      default: true
    }
  },
  components: { ProductListSlider },
  mixins: [deliveryReactive],
  data() {
    return {
      products: [],
      showMore: false
    };
  },
  methods: {
    // handleView(entries, observer, isIntersecting) {
    //   if (isIntersecting) {
    //     analyticsService.viewPromotions(
    //       [this.proposal],
    //       this.position,
    //       this.products
    //     );
    //   }
    // },
    async reload() {
      this.products = [];

      let layout = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductSlider.LAYOUT"
      );
      let categoryId = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductSlider.CATEGORY.categoryId"
      );
      let subCategoryId = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductSlider.SUB_CATEGORY.categoryId",
        undefined
      );
      let query = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductSlider.QUERY",
        undefined
      );
      let limit = Vue.$ebsn.meta(
        this.config,
        "categoryblocktype_ProductSlider.PRODUCT_LIMIT",
        12
      );
      if (layout) {
        let params = {
          layout: layout,
          product_id: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.PRODUCT_ID",
            undefined
          ),
          category_id: categoryId,
          limit: limit,
          shuffle: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.SHUFFLE"
          ),
          promo: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.PROMO"
          ),
          userId: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.USER_ID"
          ),
          warehouseId: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.WAREHOUSE_ID"
          ),
          timeslotId: Vue.$ebsn.meta(
            this.config,
            "categoryblocktype_ProductSlider.TIMESLOT_ID"
          )
        };
        let response = await ProductService.adv(params);
        if (response) {
          this.products = response.products;
        }
      } else if (categoryId || query) {
        let params = {};
        if (query) {
          params = {
            q: query,
            page_size: limit
          };
        } else {
          params = {
            parent_category_id: categoryId,
            category_id: subCategoryId,
            page_size: limit
          };
        }
        let response = await ProductService.search(params);
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
        // this.$emit("loaded", this.products ? this.products.length : 0);
      }
      if (!this.products || this.products.length < 1) {
        this.$emit("hide");
      }
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        const position = get(this.config, "templateBlock.codInt");
        analyticsService.viewProducts(this.products, this.getName(), position);
      }
    },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.getName(),
        payload.index
      );
    },
    getName() {
      const categoryId = get(
        this.config,
        "metaData.categoryblocktype_ProductSlider.CATEGORY.categoryId"
      );
      const proposalTitle = get(
        this.config,
        "metaData.categoryblocktype_ProductSlider.TITLE"
      );
      return proposalTitle || "promo_" + categoryId;
    }
  },
  async created() {
    await this.reload();
  }
};
</script>
