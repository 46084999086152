<template>
  <div class="banner-slider-container h-inherit">
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerSlider.TITLE"
      tag="h2"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_BannerSlider.DESCRIPTION"
      tag="div"
    />

    <div
      class="slider banner-slider h-inherit"
      v-if="config.proposals && config.proposals.length > 0"
    >
      <swiper :options="swiperOption" ref="swiperRef" class="h-inherit">
        <swiper-slide v-for="proposal in config.proposals" :key="proposal.id">
          <component
            :is="getProposalModelName(proposal)"
            :proposal="proposal"
            :position="config.templateBlock.codInt"
          />
        </swiper-slide>
      </swiper>
      <swiper-paginator
        :showBullets="
          $ebsn.meta(
            config,
            'categoryblocktype_BannerSlider.SHOW_BULLETS',
            true
          )
        "
        :showArrows="
          $ebsn.meta(
            config,
            'categoryblocktype_BannerSlider.SHOW_ARROWS',
            false
          )
        "
        :paginationClass="`banner-${config.categoryBlockId}`"
        :length="config.proposals.length"
      />
    </div>
  </div>
</template>
<style lang="scss">
.banner-slider {
  .swiper-slide {
    height: auto;
    .proposal-card.v-card {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  // @media #{map-get($display-breakpoints, 'xs-only')} {
  //   .swiper-button-prev,
  //   .swiper-button-next {
  //     display: none;
  //   }
  // }
  // @media #{map-get($display-breakpoints, 'sm-and-up')} {
  //   .swiper-pagination {
  //     display: none;
  //   }
  // }
}
</style>
<script>
import ProposalImage from "./elements/ProposalImage.vue";
import ProposalCard from "./elements/ProposalCard.vue";
import ProposalButton from "./elements/ProposalButton.vue";

import deliveryReactive from "~/mixins/deliveryReactive";
import categoryBlockType from "./categoryBlockType";
import SwiperPaginator from "../SwiperPaginator.vue";

export default {
  name: "BannerSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true }
  },
  components: {
    ProposalImage,
    ProposalCard,
    ProposalButton,
    SwiperPaginator
  },
  mixins: [deliveryReactive, categoryBlockType],
  data() {
    return {
      swiperRef: null,
      swiperDefaultOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        loop: false,
        pagination: {
          el: `#pagination-banner-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#prev-banner-${this.config.categoryBlockId}`,
          nextEl: `#next-banner-${this.config.categoryBlockId}`
        }
      }
    };
  },
  methods: {
    reload() {
      if (this.$refs.swiperRef.swiperInstance) {
        this.$refs.swiperRef.swiperInstance.slideTo(0);
      }
      if (!this.config.proposals || this.config.proposals.length < 1) {
        this.$emit("hide");
      }
    }
  },
  created() {
    this.$emit("hide");
  }
};
</script>
