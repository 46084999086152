<template>
  <v-tooltip left v-if="limit > 0" max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <div
        class="max-acq"
        v-bind="attrs"
        v-on="on"
        :aria-label="`max ${limit} pz`"
        role="button"
      >
        <span>max {{ limit }} pz</span>
      </div>
    </template>
    <span v-text="tooltip"> </span>
  </v-tooltip>
</template>
<style lang="scss">
.max-acq {
  max-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: absolute;
  top: 170px;
  right: 6px;
  span {
    text-transform: uppercase;
    padding: 5px;
    color: var(--v-default-base);
    line-height: 1;
    background-color: #fff99f;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    text-align: center;
    border-radius: $border-radius-root;
    justify-content: center;
    align-items: center;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .max-acq {
    top: 140px;
    right: 8px;
  }
}
</style>
<script>
import get from "lodash/get";
export default {
  name: "ProductAcqLimit",
  props: ["product"],
  data() {
    return {
      useProductClassRuleMax: false
    };
  },
  computed: {
    limit() {
      let max = get(this.product, "productClassRuleMax.max", 0);

      if (
        this.product.maxAcq &&
        this.product.productClassRuleMax &&
        this.product.productClassRuleMax.max
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.useProductClassRuleMax = !(max > this.product.maxAcq);
      } else if (!this.product.maxAcq) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.useProductClassRuleMax = true;
      } else if (
        !(
          this.product.productClassRuleMax &&
          this.product.productClassRuleMax.max
        )
      ) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.useProductClassRuleMax = false;
      }

      let maxAcq = this.useProductClassRuleMax ? max : this.product.maxAcq;
      let multiPack = this.useProductClassRuleMax
        ? 1
        : get(this.product, "productInfos.MULTI_PACK", 1);
      return maxAcq / multiPack;

      // let max = get(this.product, "productClassRuleMax.max");
      // let maxA = get(this.product, "maxAcq");
      // let maxAcq = this.getMaxAcq(max, maxA);
      // // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.useProductClassRuleMax = !(max > maxAcq);
      // let multiPack = get(
      //   this.product,
      //   "productInfos.MULTI_PACK",
      //   1
      // );
      // return maxAcq / multiPack;
    },
    tooltip() {
      if (this.useProductClassRuleMax) {
        return this.$t(
          "product.maxAcqClassTooltip",
          this.product.productClassRuleMax
        );
      } else {
        return this.$t("product.maxAcqTooltip", { count: this.limit });
      }
    }
  },
  methods: {
    getMaxAcq(max, maxA) {
      let result = undefined;
      if (max && maxA) {
        result = !(max > maxA) ? max : maxA;
      } else if (!max && maxA) {
        result = maxA;
      } else if (max && !maxA) {
        result = max;
      }
      return result;
    }
  }
};
</script>
