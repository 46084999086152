var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-slider-container h-inherit"},[_c('ebsn-meta',{attrs:{"target":_vm.config,"path":"categoryblocktype_BannerSlider.TITLE","tag":"h2"}}),_c('ebsn-meta',{attrs:{"target":_vm.config,"path":"categoryblocktype_BannerSlider.DESCRIPTION","tag":"div"}}),(_vm.config.proposals && _vm.config.proposals.length > 0)?_c('div',{staticClass:"slider banner-slider h-inherit"},[_c('swiper',{ref:"swiperRef",staticClass:"h-inherit",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.config.proposals),function(proposal){return _c('swiper-slide',{key:proposal.id},[_c(_vm.getProposalModelName(proposal),{tag:"component",attrs:{"proposal":proposal,"position":_vm.config.templateBlock.codInt}})],1)}),1),_c('swiper-paginator',{attrs:{"showBullets":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_BannerSlider.SHOW_BULLETS',
          true
        ),"showArrows":_vm.$ebsn.meta(
          _vm.config,
          'categoryblocktype_BannerSlider.SHOW_ARROWS',
          false
        ),"paginationClass":`banner-${_vm.config.categoryBlockId}`,"length":_vm.config.proposals.length}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }