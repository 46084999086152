<template>
  <v-card
    outlined
    class="product-card"
    :class="[promoClass]"
    :id="`product-card-${product.slug}`"
    :to="{ name: 'Product', params: { slug: product.slug } }"
    ref="productCard"
    :key="componentKey"
  >
    <div class="product" @click="$emit('selectProduct')">
      <div class="d-flex justify-end align-center w-100 top">
        <div class="d-flex">
          <img
            v-if="vendor"
            width="36"
            height="36"
            :src="vendor.iconSource"
            :alt="`Immagine marchio ${vendor.name}`"
          />
          <v-tooltip left :key="heartKey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click.stop.prevent="addToFavorites"
                @mousedown.stop
                aria-label="Aggiungi ad una lista"
              >
                <transition name="pulse">
                  <v-icon color="primary" :key="highlight">
                    {{ highlight ? "$heartfull" : "$heart" }}
                  </v-icon>
                </transition>
              </v-btn>
            </template>
            <span>{{ $t("product.addToList") }}</span>
          </v-tooltip>
        </div>
      </div>
      <ProductAcqLimit :product="product" />
      <div class="w-100">
        <div class="d-flex flex-column body">
          <img
            width="170"
            height="170"
            :src="product.mediaURL"
            onerror="this.onerror=null;this.src='/no-icon.png'"
            class="product-img
          align-self-center"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />
          <div
            v-if="
              product.warehousePromo &&
                product.warehousePromo.view &&
                product.warehousePromo.view.bubble
            "
            class="promo_bubble"
            v-html="product.warehousePromo.view.bubble"
          ></div>

          <div
            class="description default--text d-flex flex-column justify-left w-100 mt-3 ml-1 ml-sm-0"
          >
            <span class="name">
              {{ product.name }}
            </span>
            <div class="">
              <span class="short_descr">
                {{ product.shortDescr }}
              </span>
              <div class="d-flex align-center">
                <span class="descr" v-if="product.description">
                  {{ product.description }}</span
                >
                <span
                  v-if="
                    product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                      product.priceUmDisplay
                  "
                  class="weight-unit"
                >
                  {{ $n(product.priceUmDisplay, "currency") }}/{{
                    product.weightUnitDisplay
                  }}
                </span>
                <span
                  v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
                  class="weight-unit descr"
                >
                  <!-- <span v-else class="weight-unit descr"> -->
                  {{ minQuantity }} {{ unit }}
                </span>

                <SelectedOptionsMenu
                  v-if="
                    quantity > 0 || (selectedOptions && selectedOptions.name)
                  "
                  :product="product"
                  :selectOptionsArray="selectOptionsArray"
                  :selectedOptionSelected="selectedOptions"
                  @onOptionChanged="onOptionChanged"
                  @setSelectOptions="setSelectOptions"
                />
              </div>
            </div>

            <span class="product-classes-wrapper">
              <ProductClass
                :pClass="pClass"
                v-for="pClass in promoProductClasses"
                :key="pClass.productClassid"
              />
            </span>
            <!-- <span
              class="option d-flex align-center justify-space-between line-height-1 mt-2"
              style="white-space: initial;text-align: left;"
              v-for="(value, label) in selectedOptions"
              :key="label"
            >
              <div>
                <i>
                  Preferenza: <b> {{ value }}</b></i
                >
              </div>
            </span> -->
          </div>
        </div>

        <!-- <div
          v-if="selectedOptions.customWeight"
          class="cart-item-info"
          @click.stop.prevent="openOptionsModal(product.selectOptions)"
        >
          <em>Grammatura: {{ selectedOptions.customWeight }}gr</em>
        </div> -->
        <!-- v-if="product.priceDisplay && product.available > 0" -->
      </div>
    </div>

    <div class="actions" @click.stop.prevent>
      <div
        class="promo-wrapper-product-card"
        :id="`promo-wrapper-${product.productId}`"
      >
        <v-chip
          v-if="product.newProduct"
          class="new text-uppercase black--text"
          color="accent"
        >
          {{ $t("filter.new") }}
        </v-chip>
        <ProductPromo
          v-if="product.warehousePromo"
          :warehousePromo="product.warehousePromo"
        />
        <template
          v-else-if="
            product.userGiftCertificates &&
              product.userGiftCertificates.length > 0
          "
        >
          <product-gift-certificate
            v-for="userGiftCertificate in product.userGiftCertificates"
            :key="userGiftCertificate.giftCertificate.giftCertificateId"
            :giftCertificate="userGiftCertificate.giftCertificate"
            :containerRef="$refs.productCard"
            @add="giftAdded"
            :wrapperId="`promo-wrapper-${product.productId}`"
          />
        </template>
      </div>
      <ProductPrice v-if="product.priceDisplay" :product="product" />
      <v-spacer />
      <DayLock
        v-if="product.dayLock"
        :days="product.dayLock"
        :showTooltip="true"
      />
      <product-lead-time
        v-if="product.productInfos && product.productInfos.LEAD_TIME"
        :time="product.productInfos.LEAD_TIME"
        :showTooltip="true"
      />
      <!-- <SelectedOptionsMenu
        v-if="quantity <= 0"
        :product="product"
        :selectOptionsArray="selectOptionsArray"
        :size="'medium'"
        @onOptionChanged="onOptionChanged"
        @setSelectOptions="setSelectOptions"
      /> -->
      <ProductQty
        v-if="product.available > 0"
        :key="key"
        :selectedOptions="selectedOptions"
        :product="product"
        :item="item"
        :position="position"
      />
      <div v-else class="product-not-available">
        <!-- Lockers: 
        3 ; Liguria
        7 ; Lombardia
        11 ; Novacoop -->
        <span
          v-if="
            (cart.shippingAddress.deliveryServiceId === 3 ||
              cart.shippingAddress.deliveryServiceId === 7 ||
              cart.shippingAddress.deliveryServiceId === 11) &&
              product.productInfos &&
              product.productInfos.LOCKER_LEVEL
          "
          >{{ $t("product.notAvailableForLocker") }}</span
        >
        <span v-else-if="product.dayLock">{{ $t("product.dayLock") }}</span>
        <span v-else>{{ $t("product.notAvailable") }}</span>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.product-card {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: 225px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    min-height: 354px;
  }

  .product-img {
    width: 170px;
    height: 170px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0px 8px 0px 4px;
      width: 100px;
      height: 100px;
    }
    @media (max-width: 375px) {
      margin: 0px;
    }
  }
  .pulse-enter,
  .pulse-leave {
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
  }
  .actions {
    height: 58px;
    .v-menu__content {
      border-radius: $border-radius-root !important;
    }
    .selectOptions {
      margin: 4px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
  .iva {
    font-size: 12px;
    white-space: nowrap;
  }
  .price-wraper {
    min-height: 60px;
  }
  .top {
    position: absolute;
    top: 4px;
    right: 4px;
    width: fit-content;
  }

  .actions {
    background-color: #f5f5f5;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;
    margin-top: 6px;
    padding: 0px 6px;
  }

  .product-classes-wrapper {
    display: flex;
    margin-top: 5px;
    .product-class .product-class-icon {
      max-width: 25px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
  &.has-promo {
    .product-classes-wrapper {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        top: 55px;
      }
    }
  }
}
.product-card {
  transition: border-color 0.3s ease;
  &:hover {
    border: 1px solid $primary;
  }
}
</style>
<script>
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
import ProductQty from "./ProductQty.vue";
// import ProductManualInput from "./ProductManualInput.vue";
import ProductClass from "./ProductClass.vue";
// import ProductInfo from "./ProductInfo.vue";
// import LeadTime from "./ProductLeadTime.vue";
// import LockCutoff from "./ProductLockCutoff.vue";
// import AxBCutoff from "./ProductAxBCutoff.vue";
import DayLock from "./ProductDayLock.vue";
// import ProductGiftCertificate from "@/components/gift/ProductGiftCertificate.vue";

import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import ProductPromo from "./ProductPromo.vue";
import ProductGiftCertificate from "../gift/ProductGiftCertificate.vue";
import ProductLeadTime from "./ProductLeadTime.vue";
import cartService from "~/service/cartService";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined }
  },
  components: {
    // ProductInfo,
    ProductClass,
    ProductAcqLimit,
    ProductPrice,
    // ProductGiftCertificate,
    ProductQty,
    // ProductManualInput,
    SelectedOptionsMenu,
    // LeadTime,
    // LockCutoff,
    // AxBCutoff,
    DayLock,
    ProductPromo,
    ProductGiftCertificate,
    ProductLeadTime
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      // selectablePromo: {},
      componentKey: 0,
      key: 0
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId != 10002
      );
    },
    vendor() {
      return this.product.productClasses.find(
        element => element.productClassGroupId == 10002
      );
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.newProduct) {
        productClasses.push("new-product");
      }
      // if (this.product.edge) {
      //   productClasses.push(this.product.badge.cssClass);
      // }
      return productClasses;
    },
    minQuantity() {
      return cartService.plus(this.product);
    }

    // getItemUseWpId() {
    //   let useWpId = null;
    //   if (this.getItem) {
    //     useWpId = get(this.getItem, "cartItemInfo.use_wpid");
    //   }
    //   return useWpId;
    // }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    }
  },
  mounted() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
    // if (this.product?.warehousePromo?.selectable) {
    //   this.selectOptionsArray.push({
    //     name: "Prodotto in scadenza",
    //     parameterName: "use_wpid",
    //     options: [
    //       {
    //         key: undefined,
    //         value: this.$t("product.selectablePromo.fastConsume.promoYes")
    //       },
    //       {
    //         key: this.product.warehousePromo.warehousePromoId,
    //         value: this.$t("product.selectablePromo.fastConsume.promoNo")
    //       }
    //     ]
    //   });
    // }
  }
};
</script>
