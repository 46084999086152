<template>
  <v-card class="popup-card">
    <v-card-title v-if="popup.TITLE" class="popup-title">{{
      popup.TITLE
    }}</v-card-title>
    <v-card-text
      v-if="popup.DESCRIPTION"
      v-html="popup.DESCRIPTION"
      class="popup-description"
    ></v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="primary" depressed min-width="150" @click="submit()"
        >Chiudi</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<style lang="scss">
.v-dialog.vuedl-layout {
  .popup-card.v-card {
    .v-card__text {
      padding-top: 10px !important;
    }
  }
}
.popup-card {
  .popup-title {
    font-size: 20px;
  }
}
</style>

<script>
import { mapActions } from "vuex";

export default {
  name: "Popup",
  props: {
    popup: { type: Object, required: true },
    categoryId: { type: Number, required: true }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setPopupMap: "app/setPopupMap"
    }),
    submit() {
      this.setPopupMap(this.categoryId + "" + this.popup.TITLE);
      this.$emit("submit", true);
    }
  }
};
</script>
