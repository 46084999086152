import Vue from "vue";
import VSnackbars from "./components/VSnackbars";

let snackbars = null;

function showToaster(level, payload, options) {
  if (!snackbars) {
    // component init
    var ComponentClass = Vue.extend(VSnackbars);
    snackbars = new ComponentClass({ bottom: true });
    snackbars.$mount();
    global.vm.$el.appendChild(snackbars.$el);
  }
  if (options.max) {
    snackbars.max = options.max;
  }
  decodeMessage(payload);
  snackbars.objects.push({
    message: payload.message,
    color: level,
    transition: options.transition || "fade-transition",
    timeout: options.duration | 3000,
    top: options.top
  });
}

function showConfirm(payload) {
  decodeMessage(payload);
  global.vm.$dialog.confirm({
    text: global.EventBus.$t(payload.message),
    title: global.EventBus.$te(payload.title)
      ? global.EventBus.$t(payload.title)
      : "",
    icon: false,
    actions: {
      false: global.EventBus.$t("common.no"),
      true: {
        color: "red",
        text: global.EventBus.$t("common.yes"),
        handle: () => {
          if (payload.callback) payload.callback(true);
        }
      }
    }
  });
}

function showNotification(
  payload,
  notificationOptions = { showIcon: false, actions: { flat: true } }
) {
  decodeMessage(payload);
  global.vm.$dialog.warning({
    title: payload.title,
    text: payload.message,
    icon: notificationOptions.showIcon,
    actions: {
      login: {
        flat: notificationOptions.actions.flat,
        text: global.EventBus.$t("common.ok"),
        handle: () => {
          if (payload.callback) payload.callback(true);
        }
      }
    }
  });
}

function showResponse(response, level) {
  if (Array.isArray(response)) {
    for (let i = 0; i < response.length; i++) {
      let realLevel = global.config.showInPopupCode?.includes(response[i].code)
        ? "notification"
        : level;
      let title = global.EventBus.$t(`common.${level}`);
      global.EventBus.$emit(realLevel, {
        level: level,
        title: title,
        code: response[i]["code"],
        message: response[i]["message"]
      });
    }
  } else {
    let realLevel = global.config.showInPopupCode?.includes(response.code)
      ? "notification"
      : level;
    global.EventBus.$emit(realLevel, {
      message: response
    });
  }
}

function decodeMessage(payload) {
  if (payload.code && global.EventBus.$te(payload.code)) {
    payload.message = global.EventBus.$t(payload.code);
  }
}

export { showToaster, showConfirm, showNotification, showResponse };
