/* eslint-disable no-undef */
import Vue from "vue";
import VueRouter from "vue-router";
import VueRouterBackButton from "vue-router-back-button";
import routes from "./routes/index.js";
import store from "../store";

import isArray from "lodash/isArray";
import get from "lodash/get";
import { runScript } from "~/service/ebsn.js";
import { checkPopup, getFullName } from "@/customEbsn";

import productService from "~/service/productService";
import cmService from "~/service/mksCmService";
import addressService from "~/service/addressService";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE, // "hash" per Cordova. "History" per Browser
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    //savedPosition funziona solo quando navigo con i tasti del browser e non tramite i link dell'app
    if (savedPosition) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return { x: 0, y: 0 };
    }
  }
});

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});
const setCategory = async (_slug, to) => {
  const slug = isArray(_slug) ? _slug : [_slug];
  //navigation to category

  for (let i = 0; i < slug.length; i++) {
    // resetFilters: "category/resetFilters";

    await store.dispatch("category/setCategory", {
      slug: slug[i],
      query: to.query.q
    });
  }
  let category = store.state.category.category;
  if (category) {
    await store.dispatch("category/resetFilters", category.slug);
    to.meta.gtm = get(
      category,
      "metaData.category_seo.SEO_TITLE",
      category ? category.name : ""
    );
    runScript(category);
    checkPopup(category);
  }
};
router.beforeEach(async (to, from, next) => {
  const fetchDataAndNext = async function(to, from, next) {
    if (to.meta.slug) {
      await setCategory(to.meta.slug, to, from, next);
    } else if (to.params.pathMatch) {
      await setCategory(to.params.pathMatch, to, from, next);
    } else if (to.params.slug) {
      //navigation to product detailche
      var data = await productService.getProductBySlug(to.params.slug);
      if (data) {
        to.params.product = data;
        to.meta.gtm = getFullName(data);
        // to.metaInfo.title = getFullName(data);
      }
    } else if (to.params.pageName) {
      let data = await cmService.getArticle({ slug: to.params.pageName });
      to.params.article = data.data;
      to.meta.gtm = data.data.title;
    } else if (to.meta.pageName) {
      // let data = await CMSService.getPage(to.meta.pageName);
      // if (data.length > 0) {
      //   to.params.page = data[0];
      //   to.meta.gtm = to.params.page.meta.seo_title
      //     ? to.params.page.meta.seo_title
      //     : to.params.page.title.rendered;
      // }
    } else if (to.params.warehouseSlug) {
      let data = await addressService.findWarehouse({
        parent_warehouse_slug: to.params.warehouseSlug
      });
      if (data?.warehouses?.length > 0) {
        to.params.warehouse = data.warehouses[0];
      }
    } else {
      to.meta.gtm = to.name;
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "content-view",
        "content-name": to.fullPath,
        "content-view-name": to.meta.gtm,
        "screen-name": to.name
      });
    }
    next();
  };

  let dialog = document.querySelector("div.v-dialog__content");
  if (dialog && dialog.length > 0) {
    const IsItABackButton = window.popStateDetected;
    window.popStateDetected = false;
    if (IsItABackButton) {
      vm.$dialog.clearDialogs();
      next(false);
      return "";
    }
    fetchDataAndNext(to, from, next);
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account")
      ) {
        fetchDataAndNext(to, from, next);
      } else if (store && store.getters["cart/isAuthenticated"] == false) {
        next({
          name: "login",
          params: {
            redirectParams: {
              name: to.name,
              path: to.fullPath,
              params: to.params
            }
          }
        });
        // next({ name: "Home" });
      } else {
        if (to.matched.some(record => record.meta.isAdmin)) {
          if (store && store.getters.isSsoUser == true) {
            fetchDataAndNext(to, from, next);
          } else {
            next({
              name: "userboard"
            });
          }
        } else {
          fetchDataAndNext(to, from, next);
        }
      }
    } else {
      fetchDataAndNext(to, from, next);
    }
  }
});
Vue.use(VueRouterBackButton, { router });

export default router;
