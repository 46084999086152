<template>
  <v-btn
    class="mr-2"
    :class="field.element_class"
    color="primary"
    large
    type="submit"
    v-model="field.value"
    @click="onSubmit"
  >
    {{ field.label }}
  </v-btn>
</template>
<script>
export default {
  name: "NinjaFormsSubmitBtn",
  props: { field: { type: Object } },
  methods: {
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>
