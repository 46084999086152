import axios from "~/plugins/axios";
import CartService from "./cartService";

export default {
  getStoreProximity(services, orderBy) {
    return axios
      .get("/ebsn/api/store-proximity/list", {
        params: {
          // distanza: 500000, // GPA: Parametro fisso per far ritornare gli Stores. Da eliminare
          services: services.length > 0 ? services.join(",") : null,
          orderby: orderBy
        }
      })
      .then(data => {
        return data.data.data.stores;
      });
  },
  getStoreProximityByCoordinates(lat, lon, services, orderBy) {
    return axios
      .get("/ebsn/api/store-proximity/localize", {
        params: {
          latitude: lat,
          longitude: lon,
          // distanza: 500000, // GPA: Parametro fisso per far ritornare gli Stores. Da eliminare
          services: services ? services.join(",") : null,
          orderby: orderBy
        }
      })
      .then(data => {
        return data.data.data.stores;
      });
  },
  selectStore(selectedStore) {
    return axios
      .get("/ebsn/api/store-proximity/select", {
        params: { store_id: selectedStore.storeId }
      })
      .then(() => {
        //TODO GPA: Una volta fatta la STORE SELECT verrà fatta una nuova GetCart
        CartService.getCart(true).then(() => {
          return selectedStore;
        });
      });
  },
  getStores() {
    return axios.get("/ebsn/api/store/list").then(data => {
      if (data && data.data) {
        return data.data.data;
      } else {
        return null;
      }
    });
  },
  getStoreById(storeId) {
    return axios
      .get("/ebsn/api/store/view", {
        params: { store_id: storeId }
      })
      .then(data => {
        if (data && data.data) {
          return data.data.data;
        } else {
          return null;
        }
      });
  },
  getAddressesByDeliveryService(storeId) {
    return axios
      .get("/ebsn/api/user-address/delivery-service-list", {
        params: { delivery_service_id: storeId }
      })
      .then(data => {
        //per l'utente anonimo non è possibile visualizzare la timeslot list.
        // se è necessario visualizzare il carrello anonimo, va aggiunta la condizione isAnonymousCart (true).
        // l'utente anonimo può vedere la lista con userId = -1
        return data.data.data?.addresses;
      });
  },
  getListStore(storeId) {
    return axios
      .get("/ebsn/api/user-address/list-store", {
        params: { store_id: storeId }
      })
      .then(data => {
        if (data.data.data.addresses) {
          return data.data.data.addresses;
        }
        return [];
      });
  }
};
