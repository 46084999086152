import goTo from "vuetify/lib/services/goto";
import EmbedVideo from "~/components/EmbedVideo.vue";
import { videoURLMatcher } from "~/helper";

export default {
  methods: {
    clicked(e) {
      var target = findAnchor(e.target, 1);
      if (target && global.config.domainWhitelist.includes(target.hostname)) {
        //exclude photos and wordpress
        let resolved = false;
        if (
          !target.pathname.startsWith("/p/") &&
          !target.pathname.startsWith("/photo/") &&
          !target.pathname.startsWith("/photo2/")
        ) {
          resolved = this.$router.resolve({
            path: target.pathname.startsWith("/shop")
              ? target.pathname.substring(5)
              : target.pathname
          });
        }

        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute" &&
          target.target != "_system" &&
          target.target != "_blank"
        ) {
          if (target.hash) {
            goTo(target.hash);
          } else {
            e.preventDefault();
            try {
              if (target.search) {
                this.$router.push(target.pathname + target.search);
              } else {
                this.$router.push(resolved.route);
              }
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          if (target.href.indexOf("http") > -1) {
            if (this.$platform_is_cordova) {
              //if app open on _system browser
              e.preventDefault();
              window.cordova.InAppBrowser.open(target.href, "_system");
            } else {
              // if site open in a new tab
              e.preventDefault();
              window.open(target.href);
            }
          }
        }
      } else if (target?.href.match(videoURLMatcher)) {
        //handle youtube links
        e.preventDefault();
        global.vm.$dialog.show(EmbedVideo, {
          waitForResult: true,
          fullscreen: global.vm.$vuetify.breakpoint.xsOnly,
          width: 700,
          videoURL: target.href
        });
      } else {
        if (target?.href.indexOf("http") > -1) {
          if (this.$platform_is_cordova) {
            //if app open on _system browser
            e.preventDefault();
            window.cordova.InAppBrowser.open(target.href, "_system");
          } else {
            // if site open in a new tab
            e.preventDefault();
            window.open(target.href);
          }
        }
      }
      function findAnchor(target, i) {
        if (target && target.hostname) {
          return target;
        } else if (i < 5) {
          return findAnchor(target.parentElement, ++i);
        } else {
          return null;
        }
      }
    }
  }
};
