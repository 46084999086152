import OrderService from "~/service/orderService";

const UPDATE_ORDER_TO_PAY = "updateOrderToPay";
const UPDATE_MENU_POSITION_MODEL = "updateMenuPositionModel";
const SET_APPLICATION_CONFIG = "setApplicationConfig";

const state = {
  orderToPay: [],
  menuPositionModel: 0,
  applicationConfig: {}
};
const getters = {
  getMenuPositionModel: state => {
    return state.menuPositionModel || 0;
  },
  getApplicationConfig: state => {
    return state.applicationConfig;
  },
  isPointPolvLiguriaActive: state => {
    return state.applicationConfig.POLV_POINT_LIGURIA
      ? state.applicationConfig.POLV_POINT_LIGURIA
      : false;
  }
};

const actions = {
  async [UPDATE_ORDER_TO_PAY]({ commit }, value) {
    try {
      if (value) {
        commit(UPDATE_ORDER_TO_PAY, value);
        return true;
      } else {
        let orders = await OrderService.getOrdersRetryPayment();

        let ordersRetryPayment =
          orders.orders && orders.orders.length > 0
            ? orders.orders.map(order => order.orderId)
            : [];

        commit(UPDATE_ORDER_TO_PAY, ordersRetryPayment);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  [UPDATE_MENU_POSITION_MODEL]({ commit }, value) {
    commit(UPDATE_MENU_POSITION_MODEL, value);
  },
  [SET_APPLICATION_CONFIG]({ commit }, value) {
    commit(SET_APPLICATION_CONFIG, value);
  }
};
const mutations = {
  [UPDATE_ORDER_TO_PAY]: (state, value) => {
    state.orderToPay = value;
  },
  [UPDATE_MENU_POSITION_MODEL]: (state, value) => {
    state.menuPositionModel = value;
  },
  [SET_APPLICATION_CONFIG]: (state, value) => {
    state.applicationConfig = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
