<template functional>
  <div>
    <div
      v-if="props.showBullets && props.length > 1"
      :id="`pagination-${props.paginationClass}`"
      class="swiper-pagination"
    ></div>
    <div
      v-if="props.showArrows && props.length > 1"
      :id="`prev-${props.paginationClass}`"
      class="swiper-button-prev"
    ></div>
    <div
      v-if="props.showArrows && props.length > 1"
      :id="`next-${props.paginationClass}`"
      class="swiper-button-next"
    ></div>
  </div>
</template>
<script>
export default {
  name: "SwiperPaginator",
  props: {
    showBullets: { type: Boolean, default: false },
    showArrows: { type: Boolean, default: false },
    paginationClass: { default: "swiper" },
    length: { type: Number, default: 0 }
  }
};
</script>
