<template>
  <div class="article-list">
    <h3>{{ title }}</h3>
    <article-card
      v-for="item in articleList"
      :key="item.articleId"
      :article="item"
      size="small"
      class="mb-3"
    />
  </div>
</template>
<style scoped></style>
<script>
import ArticleCard from "./ArticleCard.vue";
export default {
  components: { ArticleCard },
  name: "ArticleAccordionList",
  props: {
    title: {
      type: String,
      required: false
    },
    articleList: {
      type: Array,
      required: true
    }
  }
};
</script>
