<template>
  <div
    :id="`LeafletSlider-wh-${config.categoryBlockId}`"
    class="LeafletSlider-wh-row"
    :style="style"
  >
    <ebsn-meta
      :target="config"
      path="categoryblocktype_LeafletSliderWrapper.TITLE"
      tag="h2"
    />
    <ebsn-meta
      :target="config"
      path="categoryblocktype_LeafletSliderWrapper.DESCRIPTION"
      tag="div"
    />
    <LeafletsSlider
      v-if="!loading"
      :parentWarehouseId="parentWarehouseId"
      :leafletTypeId="leafletTypeId"
      :deliveryServiceId="calculatedDeliveryServiceId"
    />
  </div>
</template>
<script>
import LeafletsSlider from "@/components/leaflet/LeafletsSlider.vue";

import categoryBlockType from "./categoryBlockType";

import { mapGetters } from "vuex";

export default {
  name: "LeafletSliderWrapper",
  mixins: [categoryBlockType],
  components: { LeafletsSlider },
  props: {
    config: { type: Object, required: true }
  },
  data() {
    return {
      loading: true,
      parentWarehouseId: null,
      leafletTypeId: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    calculatedDeliveryServiceId() {
      return this.isAuthenticated ? this.getDeliveryServiceId : undefined;
    }
  },
  methods: {
    reload() {
      try {
        this.leafletTypeId = this.$ebsn.meta(
          this.config,
          "categoryblocktype_LeafletSliderWrapper.LEAFLET_TYPE"
        );
        this.parentWarehouseId = this.$ebsn.meta(
          this.config,
          "categoryblocktype_LeafletSliderWrapper.WAREHOUSE_ID"
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
