<template>
  <div class="d-flex not-available-product product-lead-time-container">
    <v-tooltip v-if="showTooltip" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="lead-time"
          v-bind="attrs"
          v-on="on"
          @click.prevent=""
          @mousedown.stop
        >
          <v-icon x-small>$clock</v-icon>
        </div>
      </template>
      <div class="tooltip-description">
        <span v-html="$t('leadTime.overlayText') + getAvailableDate"> </span>
      </div>
    </v-tooltip>
    <div v-else class="lead-time d-flex" dark>
      <v-icon x-small>$clock</v-icon>
      <p
        class="not-available-description"
        v-html="$t('leadTime.overlayText') + getAvailableDate"
      ></p>
    </div>
  </div>
</template>
<style lang="scss">
.product-lead-time-container {
  .cart-item {
    .lead-time i {
      font-size: 18px !important;
    }
    .not-available-description {
      font-size: 11px;
    }
  }
  .lead-time {
    font-weight: 800;
    font-size: 11px;
    text-transform: uppercase;
    i {
      color: $text-color !important;
      font-size: 28px !important;
    }
  }
  .tooltip-description {
    max-width: 350px;
  }
  .not-available-description {
    font-size: 13px;
    line-height: 1.1;
    text-transform: none;
    font-weight: 200;
    margin: 0px 6px 0px 6px;
    padding: 0px;
    display: flex;
    align-items: center;
  }
}
</style>
<script>
export default {
  name: "ProductLeadTime",
  props: ["time", "showTooltip"],
  computed: {
    getAvailableDate() {
      return this.$dayjs()
        .add(this.time, "hour")
        .format("dddd DD/MM [dalle ore] H");
    }
  }
};
</script>
