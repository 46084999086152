<template>
  <div>
    <small v-if="field.desc_text" v-html="field.desc_text"></small>
    <v-checkbox
      :rules="rules"
      v-model="value"
      :value="0"
      :true-value="1"
      :false-value="0"
      :color="color"
      @change="change(value)"
    >
      <template v-slot:label>
        <span v-html="field.label"></span>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
export default {
  name: "NinjaFormsCheckBox",
  props: ["rules", "color", "label", "field"],
  data() {
    return {
      selected: [],
      value: 0
    };
  },
  mounted() {
    this.change(0);
  },
  methods: {
    change(v) {
      this.value = v;
      this.$emit("checkBoxChanged", {
        value: this.value,
        field: this.field
      });
    }
  }
};
</script>
