import axios from "~/plugins/axios";

export default {
  list() {
    return axios.get("/ebsn/api/cu-form/list").then(data => {
      return data.data.data;
    });
  },
  get(cuFormId) {
    return axios
      .get("/ebsn/api/cu-form/view", {
        params: {
          cuform_id: cuFormId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  insert(cuFormTypeId, content) {
    return axios
      .post("/ebsn/api/cu-form/insert", content, {
        params: {
          cuform_type_id: cuFormTypeId
        }
      })
      .then(data => {
        return data.data;
      });
  },
  update(cuFormId, content) {
    return axios
      .put("/ebsn/api/cu-form/update", content, {
        params: {
          cuform_id: cuFormId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  listFormType() {
    return axios.get("/ebsn/api/cu-form/cuform_type/list").then(data => {
      return data.data.data;
    });
  },
  getFormType(cuFormTypeId) {
    return axios
      .get("/ebsn/api/cu-form/cuform_type/view", {
        params: {
          cuform_type_id: cuFormTypeId
        }
      })
      .then(data => {
        return data.data.data;
      });
  },
  getData(cu_form_id, user_id) {
    return axios
      .get("/ebsn/api/backoffice-access/get-form", {
        params: {
          cu_form_id: cu_form_id,
          user_id: user_id
        }
      })
      .then(data => {
        return data.data.data;
      });
  }
};
